import { Collapse, Grid, Typography } from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

interface FrequentQuestionProps {
  readonly title: string;
  readonly description: string;
  readonly noBorderTop?: boolean;
}

function FrequentQuestion(props: FrequentQuestionProps) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Grid
      item
      sx={{
        py: 1,
        width: "100%",
        borderTop: !props.noBorderTop ? "1px solid" : null,
        borderColor: "border.secondary",
        cursor: "pointer"
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Grid container justifyContent={"space-between"} sx={{ py: 1, alignItems: "center" }}>
        <Grid item xs={10}>
          <Typography variant="h6" color="button.secondary">
            {props.title}
          </Typography>
        </Grid>
        <Grid item>{expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}</Grid>
      </Grid>
      <Collapse in={expanded}>
        <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
          {props.description}
        </Typography>
      </Collapse>
    </Grid>
  );
}

export default function FrequentQuestions() {
  const { isXs } = useBreakpoints();
  return (
    <Grid container mx={isXs ? 0 : 5} mb={12}>
      <Grid item sx={{ mx: isXs ? "auto" : 1 }}>
        <Typography variant={isXs ? "h4" : "h2"} sx={{ fontWeight: 400, mb: isXs ? 1 : 3 }}>
          Frequent Questions
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          py: 4,
          px: isXs ? 2 : 4,
          backgroundColor: "card.primary",
          borderRadius: isXs ? 10 : 8
        }}
      >
        <FrequentQuestion
          noBorderTop
          title="Which features to improve photos do you provide?"
          description="Enhance to sharpen faces on the photos and to denoise the background. Colorize to turn black & white photos to colourful. Descratch to remove scratches and disturbances on old photos. Lighten to correct wrong lightning on the photos."
        />
        <FrequentQuestion
          title="How long does the processing take?"
          description="It depends on the feature. On average, it takes 5 to 60 seconds."
        />
        <FrequentQuestion
          title="Is the platform free?"
          description="Upon signing up, you get 10 free tokens to enhance photos. No credit card required. If you wish to process more photos, you can purchase one of our subscription plans."
        />
        <FrequentQuestion
          title="How can I cancel my subscription?"
          description="Subscription cancellation can be done on our customer portal page."
        />
        <FrequentQuestion
          title="Can you store up the tokens?"
          description="Yes, you can. If you did not use your tokens, you can accumulate your credits up to 5 months."
        />
        <FrequentQuestion
          title="Is it secure to pay on the website?"
          description="Absolutely. We will not save any data related to your credit cards. We cooperate with Stripe, the global renowned payment provider. Learn more about security at Stripe."
        />
        <FrequentQuestion
          title="Which payment methods do you support?"
          description="We accept debit, credit cards, Google Pay and Apple Pay as well."
        />
      </Grid>
    </Grid>
  );
}
