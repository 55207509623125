import { Grid } from "@mui/material";
import FrequentQuestions from "../components/home/FrequentQuestions";

export default function FaqPage() {
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        maxWidth: 1180,
        marginX: "auto",
        alignItems: "center",
        marginTop: "30px"
      }}
    >
      <FrequentQuestions />
    </Grid>
  );
}
