import { CircularProgress, Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardView from "../components/dashboard/DashboardView";
import { GlobalContext } from "../context/GlobalContext";
import { auth } from "../utils/firebaseUtil";
import { Subscription } from "../model/subscription";

export default function Dashboard() {
  const { user, addFreeToken } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (auth.currentUser) {
      if (user && !user.isVerified && auth.currentUser.emailVerified) {
        addFreeToken();
      }
      if (user && user.token === 10) {
        toast.info("You have 10 tokens left for today!");
      }
      if (user && user.token === 0) {
        toast.info("Oops! You're out of tokens for today. Come back tomorrow for more!");
      }
    }
  }, [user]);

  useEffect(() => {
    const successParam = searchParams.get("success");
    if (successParam && Object.values(Subscription).includes(successParam as Subscription)) {
      toast.success(`Successfully subscribed to ${successParam} package`);
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        maxWidth: 1180,
        marginX: "auto",
        alignContent: "center"
      }}
    >
      <Grid container></Grid>
      {user ? (
        <DashboardView />
      ) : (
        <CircularProgress
          sx={{
            marginX: "auto",
            mt: "30vh"
          }}
        />
      )}
    </Grid>
  );
}
