import { Grid, Typography } from "@mui/material";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import BeforeAfterIcon from "../../assets/before-after-icon.svg";
import DragAndDropView from "../../common/DragAndDropView";
import useBreakpoints from "../../hooks/useBreakpoints";

interface CardProps {
  readonly title: string;
  readonly description: string;
  readonly image?: string;
  readonly secondImage?: string;
  readonly wide?: boolean;
}

export default function Card(props: CardProps) {
  const { isXs } = useBreakpoints();
  return (
    <Grid
      item
      sx={{
        backgroundColor: "card.primary",
        borderRadius: isXs ? 10 : 5,
        px: isXs ? 2 : 4,
        py: isXs ? 3.5 : 4,
        marginY: 1.5,
        width: isXs || props.wide ? "100%" : null
      }}
    >
      <Typography variant="h4" sx={{ pb: 1, pl: isXs ? 1 : 0 }}>
        {props.title}
      </Typography>
      <Typography variant="body1" sx={{ pb: 2, color: "text.secondary", pl: isXs ? 1 : 0 }}>
        {props.description}
      </Typography>
      <Grid
        container
        sx={{
          borderRadius: isXs ? 8 : 4,
          overflow: "hidden",
          width: isXs || props.wide ? "100%" : 474,
          height: isXs ? 0 : 293,
          paddingBottom: isXs ? "61%" : undefined
        }}
      >
        {props.image && props.secondImage ? (
          <ReactBeforeSliderComponent
            firstImage={{
              imageUrl: props.image
            }}
            secondImage={{
              imageUrl: props.secondImage
            }}
            delimiterIconStyles={{
              width: 40,
              height: 40,
              backgroundImage: `url(${BeforeAfterIcon})`
            }}
          />
        ) : props.image ? (
          <ReactBeforeSliderComponent
            firstImage={{
              imageUrl: props.image
            }}
            secondImage={{
              imageUrl: props.image
            }}
            delimiterIconStyles={{
              display: "none"
            }}
            delimiterColor={"transparent"}
          />
        ) : (
          <Grid
            container
            sx={{
              alignContent: "center",
              backgroundColor: isXs ? "transparent" : "secondary.main"
            }}
          >
            <DragAndDropView />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
