import { Button, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "../common/LoadingButton";
import { toast } from "react-toastify";
import useBreakpoints from "../hooks/useBreakpoints";

export default function Settings() {
  const { user, getStripeCustomerPortalUrl, saveUser } = useContext(GlobalContext);
  const { isXs } = useBreakpoints();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
  }, [user]);

  const openCustomerPortal = async () => {
    try {
      setLoading(true);
      const url = await getStripeCustomerPortalUrl();
      window.location.replace(url);
    } catch (e: any) {
      toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const isNameChanged = () => {
    if (!firstName || !lastName) {
      return false;
    }
    return user?.firstName?.trim() !== firstName?.trim() || user?.lastName?.trim() !== lastName?.trim();
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        maxWidth: 1180,
        marginX: "auto",
        alignItems: "center"
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        sx={{
          my: 5,
          mx: isXs ? 1 : 5,
          border: "1px solid",
          borderColor: "border.secondary",
          borderRadius: 5
        }}
      >
        {!user ? (
          <CircularProgress />
        ) : (
          <Grid container mx={5} my={3}>
            <Grid container justifyContent="space-between" sx={{ my: 1 }}>
              <Grid item xs={isXs ? 12 : 5.9} sx={{ mb: isXs ? 2 : 0 }}>
                <TextField disabled id="email-input" label="Email" defaultValue={user.email} fullWidth />
              </Grid>
              <Grid item xs={isXs ? 12 : 5.9}>
                <TextField
                  disabled
                  label="Subscription"
                  id="subscription-input"
                  defaultValue={user.activeSubscription}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ my: 1 }}>
              <Grid item xs={isXs ? 12 : 5.9} sx={{ mb: isXs ? 2 : 0 }}>
                <TextField
                  disabled={!editMode}
                  id="first-name-input"
                  label="First Name"
                  defaultValue={user.firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={isXs ? 12 : 5.9}>
                <TextField
                  disabled={!editMode}
                  label="Last Name"
                  id="last-name-input"
                  defaultValue={user.lastName}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container my={1}>
              <Button
                onClick={() => {
                  if (editMode && isNameChanged()) {
                    try {
                      saveUser({
                        ...user,
                        firstName: firstName?.trim(),
                        lastName: lastName?.trim()
                      });
                      toast.success("Name updated successfully");
                    } catch (e: any) {
                      toast.error("Something went wrong, please try again");
                    }
                  }
                  setEditMode(!editMode);
                }}
                disabled={editMode && !isNameChanged()}
              >
                {!editMode ? "Edit" : "Save"}
              </Button>
            </Grid>
            <Grid container my={1}>
              <LoadingButton onClick={openCustomerPortal} loading={loading} variant="contained">
                Billing history
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
