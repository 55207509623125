import { FormGroup, Grid, TextField, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../DashboardContext";

interface Txt2ImgOptionsProps {
  readonly order: number;
}

export default function Txt2ImgOptions(props: Txt2ImgOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [prompt, setPrompt] = useState("environment living room interior, mid century modern, indoor garden with fountain, retro,m vintage, designer furniture made of wood and plastic, concrete table, wood walls, indoor potted tree, large window, outdoor forest landscape, beautiful sunset, cinematic, concept art, sustainable architecture, octane render, utopia, ethereal, cinematic light");

  useEffect(() => {
    updateOptions(props.order, {
      prompt
    });
  }, [prompt]);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Removes scratches from old photos
            <br />
            &#x2022;&nbsp; Improves photo's structure
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
      <FormGroup>
        <TextField
          id="standard-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          variant="standard"
        />
      </FormGroup>
    </Grid>
  );
}
