import {
    FormGroup,
    Grid,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import {DashboardContext} from "../DashboardContext";

interface UpscaleOptionsProps {
  readonly order: number;
}

export default function UpscaleOptions(props: UpscaleOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    updateOptions(props.order, {
      prompt,
    });
  }, [prompt]);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Removes scratches from old photos
            <br />
            &#x2022;&nbsp; Improves photo's structure
          </Typography>
        }
        placement="right"
      >
      <InfoOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
      <FormGroup>
          <TextField
          id="standard-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          variant="standard"
        />
      </FormGroup>
    </Grid>
  );
}
