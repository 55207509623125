import { Grid } from "@mui/material";
import Stories from "../../../../assets/socialmedia/stories.webp";
import Instagram from "../../../../assets/socialmedia/instagram.webp";
import TweetCard from "../../../../assets/socialmedia/tweet-card.webp";
import Social from "../../../../assets/socialmedia/social.webp";
import useBreakpoints from "../../../../hooks/useBreakpoints";

export default function SocialSharingContent() {
  const { isXs } = useBreakpoints();

  return (
    <Grid
      container={isXs}
      justifyContent={"center"}
      sx={{ position: "relative" }}
    >
      <img
        src={Stories}
        alt=""
        width={isXs ? "100%" : 310}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          maxWidth: 400,
          left: 30,
          top: 20,
        }}
      />
      <img
        src={Instagram}
        alt=""
        width={isXs ? "100%" : 310}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          maxWidth: 400,
          left: 30,
          top: 115,
          marginTop: isXs ? 30 : 0,
        }}
      />
      {!isXs && (
        <img
          src={TweetCard}
          alt=""
          width={310}
          style={{
            objectFit: "cover",
            position: "absolute",
            left: 380,
            top: 20,
          }}
        />
      )}
      <img
        src={Social}
        alt=""
        width={isXs ? "100%" : 310}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          left: 380,
          top: 530,
          marginTop: isXs ? 30 : 0,
        }}
      />
    </Grid>
  );
}
