import { useCallback, useEffect } from "react";
import { track } from "@amplitude/analytics-browser";
import { Button, Grid } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import useBreakpoints from "../../hooks/useBreakpoints";
import BuyTokenView from "./BuyTokenView";
import { DashboardContext } from "./DashboardContext";
import GetStartedView from "./GetStartedView";
import MultiselectView from "./MultiselectView";
import ProgressView from "./progress/ProgressView";
import { QueueContext, QueueContextProvider } from "./QueueContext";

function DashboardView() {
  const { user } = useContext(GlobalContext);
  const { getStartedPage, updateImagesWithDrop, setGetStartedPage } = useContext(DashboardContext);
  const { queue, intermediaryImages, finalImages, saveIntermediaryImages, saveFinalImages } =
    useContext(QueueContext);
  const { isXs } = useBreakpoints();

  const handlePasteAnywhere = useCallback(
    (event: any) => {
      let pastedImages: any[] = [];
      for (let i = 0; i < event.clipboardData.items.length; i++) {
        const item = event.clipboardData.items[i];
        if (item.type.indexOf("image") === 0) {
          var blob = item.getAsFile();
          pastedImages = [...pastedImages, blob];
        }
      }
      if (pastedImages.length) {
        updateImagesWithDrop(pastedImages);
        setGetStartedPage(false);
      }
    },
    [setGetStartedPage, updateImagesWithDrop]
  );

  useEffect(() => {
    if ((user?.token || 0) <= 0) {
      return;
    }
    window.addEventListener("paste", handlePasteAnywhere);

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
  }, [user, handlePasteAnywhere]);

  return (
    <>
      {getStartedPage ? user?.token! > 0 ? <GetStartedView /> : <BuyTokenView /> : <MultiselectView />}
      {queue.length > 0 && <ProgressView />}
      {queue.length > 0 && (
        <Grid
          container
          gap={1}
          sx={{
            mx: isXs ? 1 : 5,
            mb: 5
          }}
        >
          <Button
            variant="contained"
            disabled={intermediaryImages.length === 0}
            onClick={() => {
              track("Download All button clicked on progress bar");
              saveIntermediaryImages();
            }}
          >
            Download All ({intermediaryImages.length})
          </Button>
          <Button
            variant="contained"
            disabled={finalImages.length === 0}
            onClick={() => {
              track("Download Final Images button clicked on progress bar");
              saveFinalImages();
            }}
          >
            Download Final Images ({finalImages.length})
          </Button>
        </Grid>
      )}
    </>
  );
}

export default function DashboardViewIndex() {
  return (
    <QueueContextProvider>
      <DashboardView />
    </QueueContextProvider>
  );
}
