import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { auth } from "./utils/firebaseUtil";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "./components/layout/Navbar";
import Home from "./pages/Home";
import MyProfile from "./pages/MyProfile";
import Login from "./pages/Login";
import { ThemeProvider, Typography } from "@mui/material";
import { lightTheme } from "./style/themes";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Footer from "./components/layout/Footer";
import MobileMenu from "./pages/MobileMenu";
import Pricing from "./pages/Pricing";
import { DashboardContextProvider } from "./components/dashboard/DashboardContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toastify.css";
import { GlobalContextProvider } from "./context/GlobalContext";
import Settings from "./pages/Settings";
import FaqPage from "./pages/FaqPage";
import { useEffect } from "react";
import { track } from "@amplitude/analytics-browser";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import CookieConsent from "react-cookie-consent";

export default function App() {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  useEffect(() => {
    track(location.pathname);
  }, [location.pathname]); // Only run the effect when the location changes

  function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    if (user === null) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
  }

  return loading ? (
    <div />
  ) : (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <ThemeProvider theme={lightTheme}>
        <ToastContainer hideProgressBar={true} closeButton={false} position={"top-center"} />
        <GlobalContextProvider>
          <DashboardContextProvider>
            <Navbar />
            <div className="m-0">
              <Routes>
                <Route path="/" element={auth.currentUser ? <Dashboard /> : <Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/login" element={auth.currentUser ? <Dashboard /> : <Login />} />
                <Route path="/register" element={auth.currentUser ? <Dashboard /> : <Register />} />
                <Route path="/menu" element={<MobileMenu />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route
                  path="/settings"
                  element={
                    <RequireAuth>
                      <Settings />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/my-profile"
                  element={
                    <RequireAuth>
                      <MyProfile />
                    </RequireAuth>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
              </Routes>
            </div>
            <Footer />
            <CookieConsent
              style={{
                background: "#1355FF",
                borderRadius: "48px 48px 0px 0px",
                paddingTop: 5,
                paddingBottom: 5
              }}
              buttonStyle={{
                color: "#000000",
                backgroundColor: "#ffffff",
                fontSize: 14,
                borderRadius: "48px 48px 48px 48px",
                padding: "10px 15px"
              }}
              expires={150}
            >
              <Typography sx={{ ml: 2 }}>
                This website uses cookies to enhance the user experience.
              </Typography>
            </CookieConsent>
          </DashboardContextProvider>
        </GlobalContextProvider>
      </ThemeProvider>
    </div>
  );
}
