import { Grid, Typography } from "@mui/material";
import EnhancerBefore from "../../assets/home/Enhancer Card Before.webp";
import EnhancerAfter from "../../assets/home/Enhancer Card After.webp";
import DescratchBefore from "../../assets/home/Descratch Before.webp";
import DescratchAfter from "../../assets/home/Descratch After.webp";
import ColorizeBefore from "../../assets/home/Colorize Before.webp";
import ColorizeAfter from "../../assets/home/Colorize After.webp";
import LightenBefore from "../../assets/home/Lighten Before.webp";
import LightenAfter from "../../assets/home/Lighten After.webp";
import useBreakpoints from "../../hooks/useBreakpoints";
import Card from "./Card";

export default function Operation() {
  const { isXs } = useBreakpoints();

  return (
    <Grid container mx={isXs ? 0 : 5}>
      <Typography
        variant={isXs ? "h4" : "h2"}
        sx={{
          fontWeight: 400,
          mx: isXs ? "auto" : 1,
          mb: isXs ? 0 : 3
        }}
      >
        What can we do?
      </Typography>
      <Grid container justifyContent={isXs ? "center" : "space-between"}>
        <Card
          title="Enhance"
          description="Sharpen faces on the photos and denoise the background"
          image={EnhancerBefore}
          secondImage={EnhancerAfter}
        />
        <Card
          title="Colorize"
          description="Turn black &amp; white photos to colorful"
          image={ColorizeBefore}
          secondImage={ColorizeAfter}
        />
        <Card
          title="Descratch"
          description="Remove scratches and disturbances on old photos"
          image={DescratchBefore}
          secondImage={DescratchAfter}
        />
        <Card
          title="Lighten"
          description="Correct wrong lightning on the photos"
          image={LightenBefore}
          secondImage={LightenAfter}
        />
        <Card title="Your image" description="Download and get the result right now" wide />
      </Grid>
    </Grid>
  );
}
