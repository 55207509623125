import {
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext, LIGTHEN_TYPE } from "../DashboardContext";

interface LightenOptionsProps {
  readonly order: number;
}

export default function LightenOptions(props: LightenOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [lightenType, setLigtenType] = useState(LIGTHEN_TYPE.MILD);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setLigtenType(event.target.value as LIGTHEN_TYPE);
  };

  useEffect(() => {
    updateOptions(props.order, {
      lightenType
    });
  }, [lightenType]);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Eliminate wrong lighting on portraits
            <br />
            &#x2022;&nbsp; Correct light palette
            <br />
            &#x2022;&nbsp; Retouch like professional photographer
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" onClick={() => setShowTooltip(!showTooltip)} />
      </Tooltip>
      <FormGroup>
        <FormControl sx={{ mt: 1.5, maxWidth: 135 }} size="small">
          <InputLabel id="select-small">Lighten Type</InputLabel>
          <Select
            labelId="select-small"
            id="select-small"
            value={lightenType}
            label="Lighten Type"
            onChange={handleChange}
          >
            <MenuItem value={LIGTHEN_TYPE.COOL}>Cool</MenuItem>
            <MenuItem value={LIGTHEN_TYPE.MILD}>Mild</MenuItem>
            <MenuItem value={LIGTHEN_TYPE.WARM}>Warm</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
    </Grid>
  );
}
