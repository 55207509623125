import React, { useContext, useState } from "react";
import { auth } from "../utils/firebaseUtil";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signInWithEmailAndPassword, getAdditionalUserInfo } from "@firebase/auth";
import { Google } from "@mui/icons-material";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography
} from "@mui/material";
import useBreakpoints from "../hooks/useBreakpoints";
import { validateEmail } from "../utils/validators";
import InlineError from "../common/InlineError";
import { toast } from "react-toastify";
import { Subscription } from "../model/subscription";
import PasswordResetDialog from "../components/home/PasswordResetDialog";
import { GlobalContext } from "../context/GlobalContext";
import UserRepository from "../repository/UserRepository";
import { User } from "../model/user";
import { track } from "@amplitude/analytics-browser";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://photoenhance.ai/">
        PhotoEnhance.ai
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const navigate = useNavigate();
  const { isXs } = useBreakpoints();
  const provider = new GoogleAuthProvider();

  const { saveUser, addFreeToken } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<string>();
  const [openPasswordResetDialog, setOpenPasswordResetDialog] = useState(false);

  const [searchParams] = useSearchParams();

  async function onLoginHandler() {
    searchParams.get("fromPricing") ? navigate("/pricing") : navigate("/");
  }

  const loginWithGoogle = async () => {
    track("Sign In with Popup");
    const userCredential = await signInWithPopup(auth, provider);
    const info = getAdditionalUserInfo(userCredential);
    const user: User = (await UserRepository.getById(auth.currentUser?.uid!)) as User;
    if (info && auth.currentUser && !user) {
      saveUser({
        id: auth.currentUser.uid,
        firstName: (info.profile?.given_name as string).trim(),
        lastName: (info.profile?.family_name as string).trim(),
        email: info.profile?.email as string,
        activeSubscription: Subscription.FREE
      });
      userCredential.user.emailVerified && addFreeToken();
    }
    await onLoginHandler();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    track("Sign In with Email and Password");
    try {
      if (emailIsValid()) {
        await signInWithEmailAndPassword(auth, email, password);
        await onLoginHandler();
      }
    } catch (e: any) {
      if (e.code === "auth/wrong-password") {
        toast.error("Invalid email or password");
      } else {
        toast.error("Something went wrong, please try again");
      }
    }
  };

  const emailIsValid = (): boolean => {
    const emailError = validateEmail(email);
    setEmailError(emailError);
    return !emailError;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: isXs ? 3 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Button
          sx={{
            marginTop: 3,
            marginBottom: 2
          }}
          onClick={() => loginWithGoogle()}
          variant="contained"
          startIcon={<Google />}
        >
          Sign in with Google
        </Button>
        <Divider sx={{ p: 1, width: `100%` }} />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            value={email}
            onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
            margin="normal"
            required
            fullWidth
            error={!!emailError}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{ mb: 0.1 }}
          />
          {emailError && <InlineError msg={emailError} />}
          <TextField
            value={password}
            onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={<Typography variant="body2">Remember me</Typography>}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={[email, password].includes("")}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => {
                  track("Forgot password button clicked on login page");
                  setOpenPasswordResetDialog(true);
                }}
                variant="body2"
                sx={{ cursor: "pointer" }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={() => navigate("/register")} variant="body2" sx={{ cursor: "pointer" }}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <PasswordResetDialog
        isOpen={openPasswordResetDialog}
        onClose={() => setOpenPasswordResetDialog(false)}
      />
    </Container>
  );
}
