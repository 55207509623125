import { Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../DashboardContext";

interface EnhanceOptionsProps {
  readonly order: number;
}

export default function EnhanceOptions(props: EnhanceOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [background, setBackground] = useState(false);
  const [realistic, setRealistic] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    updateOptions(props.order, {
      realistic,
      background
    });
  }, [realistic, background]);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        arrow
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Unblur faces and make them sharper
            <br />
            &#x2022;&nbsp; Denoise and clean background
            <br />
            &#x2022;&nbsp; Use photos with fully visible faces
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" onClick={() => setShowTooltip(!showTooltip)} />
      </Tooltip>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={background} onChange={() => setBackground(!background)} />}
          label={<Typography variant="body2">Only background without faces</Typography>}
        />
        <FormControlLabel
          control={<Checkbox checked={realistic} onChange={() => setRealistic(!realistic)} />}
          label={<Typography variant="body2">Realistic look</Typography>}
          sx={{ mt: -1.2 }}
        />
      </FormGroup>
    </Grid>
  );
}
