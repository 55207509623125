import { Grid } from "@mui/material";
import Banner from "../components/home/Banner";
import Benefits from "../components/home/Benefits";
import FrequentQuestions from "../components/home/FrequentQuestions";
import HappyClients from "../components/home/HappyClients";
import MainCard from "../components/home/MainCard";
import MobileApp from "../components/home/MobileApp";
import Operation from "../components/home/Operation";
import SocialMedia from "../components/home/socialmedia/SocialMedia";
import Statistics from "../components/home/Statistics";
import useBreakpoints from "../hooks/useBreakpoints";

export default function Home() {
  const { isXs } = useBreakpoints();
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        maxWidth: 1180,
        marginX: "auto",
        alignItems: "center"
      }}
    >
      <MainCard />
      {!isXs && <Statistics />}
      <Operation />
      <Benefits />
      <SocialMedia />
      <HappyClients />
      <MobileApp />
      <FrequentQuestions />
      <Banner />
    </Grid>
  );
}
