import { Button, Grid } from "@mui/material";
import GooglePlaySVG from "../../assets/google-play.svg";
import AppleStoreSVG from "../../assets/app-store.svg";
import WhiteGooglePlaySVG from "../../assets/google-play-white.svg";
import WhiteAppleStoreSVG from "../../assets/app-store-white.svg";
import { useNavigate } from "react-router-dom";
import { track } from "@amplitude/analytics-browser";

interface MenuButtonsProps {
  readonly isWhite?: boolean;
}

export default function MenuButtons(props: MenuButtonsProps) {
  const navigate = useNavigate();

  return (
    <Grid item>
      <Button
        variant="text"
        sx={{ color: props.isWhite ? "secondary.main" : "text.primary" }}
        onClick={async () => {
          track(`Pricing button clicked on ${props.isWhite ? "footer" : "navbar"}`);
          await navigate("/pricing");
          window.scrollTo({ left: 0, top: 5080, behavior: "smooth" });
        }}
      >
        Pricing
      </Button>
      <Button
        variant="text"
        sx={{ color: props.isWhite ? "secondary.main" : "text.primary" }}
        onClick={async () => {
          track(`FAQ button clicked on ${props.isWhite ? "footer" : "navbar"}`);
          await navigate("/faq");
          window.scrollTo({ left: 0, top: 5080, behavior: "smooth" });
        }}
      >
        FAQ
      </Button>
      <Button
        variant="text"
        startIcon={
          <img src={props.isWhite ? WhiteGooglePlaySVG : GooglePlaySVG} alt="" width={15} height={15} />
        }
        onClick={() => {
          track(`Google Play button clicked on ${props.isWhite ? "footer" : "navbar"}`);
          window.open(
            "https://play.google.com/store/apps/details?id=intechsolutions.photoenhancer",
            "_blank"
          );
        }}
        sx={{ color: props.isWhite ? "secondary.main" : "text.primary" }}
      >
        Google Play
      </Button>
      <Button
        variant="text"
        startIcon={
          <img
            src={props.isWhite ? WhiteAppleStoreSVG : AppleStoreSVG}
            alt=""
            width={15}
            height={15}
            color={props.isWhite ? "secondary.main" : "text.primary"}
          />
        }
        onClick={() => {
          track(`App Store button clicked on ${props.isWhite ? "footer" : "navbar"}`);
          window.open("https://apps.apple.com/us/app/ai-photo-enhance-unblur-clear/id1599612633", "_blank");
        }}
        sx={{ color: props.isWhite ? "secondary.main" : "text.primary" }}
      >
        App Store
      </Button>
    </Grid>
  );
}
