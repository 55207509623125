import { Grid } from "@mui/material";
import { ReactNode, useContext } from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { SocialMediaContext, SocialMediaItemName } from "./SocialMediaContext";
import SocialMediaItem from "./SocialMediaItem";

interface WrapperProps {
  readonly children: ReactNode;
}

function Wrapper(props: WrapperProps) {
  const { isXs } = useBreakpoints();

  return isXs ? (
    <Grid container wrap="nowrap" sx={{ overflowX: "scroll" }}>
      {props.children}
    </Grid>
  ) : (
    <Grid>{props.children}</Grid>
  );
}

export default function SocialMediaMenu() {
  const { activeItem, setActiveItem } = useContext(SocialMediaContext);

  return (
    <Wrapper>
      <SocialMediaItem
        name={SocialMediaItemName.SOCIAL_SHARING}
        description="Share the photos in high quality on TikTok, Instagram, Facebook, etc"
        isActive={activeItem === SocialMediaItemName.SOCIAL_SHARING}
        setActive={() => setActiveItem(SocialMediaItemName.SOCIAL_SHARING)}
      />
      <SocialMediaItem
        name={SocialMediaItemName.PHOTOGRAPHERS}
        description="Improve photos for your clients"
        isActive={activeItem === SocialMediaItemName.PHOTOGRAPHERS}
        setActive={() => setActiveItem(SocialMediaItemName.PHOTOGRAPHERS)}
      />
      <SocialMediaItem
        name={SocialMediaItemName.PRINTING_SERVICES}
        description="Print pictures with better quality and higher resolution"
        isActive={activeItem === SocialMediaItemName.PRINTING_SERVICES}
        setActive={() => setActiveItem(SocialMediaItemName.PRINTING_SERVICES)}
      />
      <SocialMediaItem
        name={SocialMediaItemName.E_COMMERCE}
        description="Make your photos look professional"
        isActive={activeItem === SocialMediaItemName.E_COMMERCE}
        setActive={() => setActiveItem(SocialMediaItemName.E_COMMERCE)}
      />
      <SocialMediaItem
        name={SocialMediaItemName.WEBSITE_GRAPHICS}
        description="Improve product images"
        isActive={activeItem === SocialMediaItemName.WEBSITE_GRAPHICS}
        setActive={() => setActiveItem(SocialMediaItemName.WEBSITE_GRAPHICS)}
        mr={2}
      />
    </Wrapper>
  );
}
