import { Grid, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";

interface InlineErrorProps {
  readonly msg: string;
}

export default function InlineError(props: InlineErrorProps) {
  return (
    <Grid container alignItems="center">
      <ErrorIcon color="error" fontSize="small" />
      <Grid item>
        <Typography variant="caption" color="error" sx={{ ml: 0.4 }}>
          {props.msg}
        </Typography>
      </Grid>
    </Grid>
  );
}
