import { Grid, Typography } from "@mui/material";
import Login from "../assets/mobilemenu/login.png";
import Register from "../assets/mobilemenu/register.png";
import Home from "../assets/mobilemenu/home.png";
import Pricing from "../assets/mobilemenu/pricing.png";
import FAQ from "../assets/mobilemenu/faq.png";
import AppStore from "../assets/mobilemenu/appstore.png";
import GooglePlay from "../assets/mobilemenu/googleplay.png";
import { useNavigate } from "react-router-dom";
import useBreakpoints from "../hooks/useBreakpoints";

interface WrapperProps {
  readonly src: string;
  readonly title: string;
  readonly onClick: () => void;
}

function Wrapper(props: WrapperProps) {
  return (
    <Grid container gap={3} alignItems="center" onClick={props.onClick}>
      <Grid item>
        <img src={props.src} alt="" width={25} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{props.title}</Typography>
      </Grid>
    </Grid>
  );
}

export default function MobileMenu() {
  const navigate = useNavigate();
  const { isXs } = useBreakpoints();

  return (
    <Grid container gap={4} sx={{ px: 6, py: 5, mb: 15 }}>
      <Wrapper src={Login} title="Login" onClick={() => navigate("/login")} />
      <Wrapper src={Register} title="Register" onClick={() => navigate("/register")} />
      <Wrapper src={Home} title="Home" onClick={() => navigate("/")} />
      <Wrapper src={Pricing} title="Pricing" onClick={() => navigate("/pricing")} />
      <Wrapper
        src={FAQ}
        title="FAQ"
        onClick={async () => {
          await navigate("/faq");
          window.scrollTo({
            left: 0,
            top: isXs ? 6800 : 5080,
            behavior: "smooth"
          });
        }}
      />
      <Grid item>
        <Typography variant="body1" color="#636F7F">
          Improve your smartphone photos
        </Typography>
      </Grid>
      <Wrapper
        src={AppStore}
        title="App Store"
        onClick={() => {
          window.open(
            "https://play.google.com/store/apps/details?id=intechsolutions.photoenhancer",
            "_blank"
          );
        }}
      />
      <Wrapper
        src={GooglePlay}
        title="Google Play"
        onClick={() =>
          window.open("https://apps.apple.com/us/app/ai-photo-enhance-unblur-clear/id1599612633", "_blank")
        }
      />
    </Grid>
  );
}
