import { ChangeEvent, createContext, ReactNode, useState } from "react";

export enum JOB_TYPE {
  ENHANCE = "photo_enhance_plus",
  DESCRATCH = "photo_descratch",
  RESTORE = "photo_restore",
  COLORIZE = "photo_colorize",
  LIGHTEN = "photo_lighten",
  UPSCALE = "photo_upscale",
  TXT2IMG = "photo_txt2img",
  IMG2IMG = "photo_img2img"
}

export const activeJobTypes = [JOB_TYPE.ENHANCE, JOB_TYPE.DESCRATCH, JOB_TYPE.COLORIZE, JOB_TYPE.LIGHTEN];

export enum LIGTHEN_TYPE {
  COOL = "mask_noglc_a",
  MILD = "mask_noglc_b",
  WARM = "mask_noglc_c"
}

export interface Options {
  // Enhance
  readonly background?: boolean;
  readonly realistic?: boolean;
  // Descratch
  readonly hr?: boolean;
  // Colorize
  readonly vibrant?: boolean;
  readonly filter?: boolean;
  readonly intensity?: number;
  readonly brightness?: number;
  // Lighten
  readonly lightenType?: LIGTHEN_TYPE;
  // Upscale
  readonly prompt?: string;
  // Img2Img
  readonly promptNegative?: string;
  readonly strength?: number;
}

export interface Job {
  readonly order: number;
  readonly type: JOB_TYPE;
  readonly options: Options;
}

export const IMAGE_UPLOAD_LIMIT: number = +(process.env.REACT_APP_IMAGE_UPLOAD_LIMIT as string);

export interface DashboardContextValue {
  readonly images: string[];
  readonly updateImages: (files: FileList) => void;
  readonly removeImage: (index: number) => void;
  readonly updateImagesWithDrop: (files: File[]) => void;
  readonly clearImages: () => void;
  readonly jobs: Job[];
  readonly updateJobs: (job: Job) => void;
  readonly clearJobs: () => void;
  readonly updateOptions: (jobId: number, newOptions: Options) => void;
  readonly processing: boolean;
  readonly setProcessing: (processing: boolean) => void;
  readonly getStartedPage: boolean;
  readonly setGetStartedPage: (getStarted: boolean) => void;
  readonly bringJobBack: (image: string, jobs: Job[]) => void;
}

interface ProviderProps {
  children: ReactNode;
}

export const DashboardContext = createContext<DashboardContextValue>({} as any);

export const DashboardContextProvider = (props: ProviderProps) => {
  const [images, setImages] = useState<string[]>([]);
  const [jobs, setJobs] = useState<Job[]>([{ order: 0, type: JOB_TYPE.ENHANCE, options: {} }]);
  const [processing, setProcessing] = useState(false);
  const [getStartedPage, setGetStartedPage] = useState(true);

  const updateImages = (files: FileList) => {
    const newImages: string[] = [];
    for (let i = 0; i < files.length; i++) {
      newImages.push(URL.createObjectURL(files[i]));
    }
    setImages([...images, ...newImages].slice(0, IMAGE_UPLOAD_LIMIT));
  };

  const removeImage = (index: number) => {
    images.splice(index, 1);
    setImages([...images]);
  };

  const updateImagesWithDrop = (files: File[]) => {
    const newImages: string[] = [];
    for (let i = 0; i < files.length; i++) {
      newImages.push(URL.createObjectURL(files[i]));
    }
    setImages([...images, ...newImages].slice(0, IMAGE_UPLOAD_LIMIT));
  };

  const clearImages = () => {
    setImages([]);
  };

  const updateJobs = (newJob: Job) => {
    jobs.find((job) => job.order === newJob.order)
      ? setJobs(jobs.map((job) => (job.order === newJob.order ? { ...job, type: newJob.type } : job)))
      : setJobs([...jobs, newJob]);
  };

  const clearJobs = () => {
    setJobs([{ order: 0, type: JOB_TYPE.ENHANCE, options: {} }]);
  };

  const updateOptions = (jobId: number, newOptions: Options) => {
    setJobs(
      jobs.map((job) => (job.order === jobId ? { ...job, options: { ...job.options, ...newOptions } } : job))
    );
  };

  const bringJobBack = (image: string, jobs: Job[]) => {
    setJobs([...jobs]);
    setImages([image]);
  };

  return (
    <DashboardContext.Provider
      value={{
        images,
        jobs,
        processing,
        getStartedPage,
        updateImages,
        removeImage,
        updateImagesWithDrop,
        clearImages,
        clearJobs,
        updateJobs,
        updateOptions,
        setProcessing,
        setGetStartedPage,
        bringJobBack
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
