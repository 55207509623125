import { JOB_TYPE } from "../components/dashboard/DashboardContext";

export function titleCase(str: string) {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export function getChipLabel(jobType: JOB_TYPE) {
  switch (jobType) {
    case JOB_TYPE.ENHANCE:
      return "Enhanced";
    case JOB_TYPE.DESCRATCH:
      return "Descratched";
    case JOB_TYPE.RESTORE:
      return "Restored";
    case JOB_TYPE.COLORIZE:
      return "Colorized";
    case JOB_TYPE.LIGHTEN:
      return "Lightened";
    default:
      return "";
  }
}
