import { PaletteOptions } from "@mui/material/styles";

export const lightPalette: PaletteOptions = {
  primary: {
    main: "#1355FF",
  },
  secondary: {
    main: "#FFFFFF",
  },
  button: {
    primary: "#1355FF",
    secondary: "#070538",
  },
  card: {
    primary: "#F0F4FF",
    secondary: "#E1E9FE",
  },
  text: {
    primary: "#000000",
    secondary: "#8A96A6",
  },
  border: {
    primary: "#D9D8DB",
    secondary: "#DEE2E6",
  },
  dot: {
    primary: "#FB5F5F",
  },
  footer: {
    primary: "#070538",
    secondary: "#9c9baf",
  },
};
