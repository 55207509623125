import { Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../DashboardContext";

interface DescratchOptionsProps {
  readonly order: number;
}

export default function DescratchOptions(props: DescratchOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [hr, setHr] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    updateOptions(props.order, {
      hr
    });
  }, [hr]);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Removes scratches from old photos
            <br />
            &#x2022;&nbsp; Improves photo's structure
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" onClick={() => setShowTooltip(!showTooltip)} />
      </Tooltip>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={hr} onChange={() => setHr(!hr)} />}
          label={<Typography variant="body2">HR</Typography>}
        />
      </FormGroup>
    </Grid>
  );
}
