import { Grid, Typography } from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";
import SocialMediaContent from "./SocialMediaContent";
import { SocialMediaContextProvider } from "./SocialMediaContext";
import SocialMediaMenu from "./SocialMediaMenu";

function SocialMedia() {
  const { isXs } = useBreakpoints();
  return (
    <Grid container mx={isXs ? 0 : 5} justifyContent={isXs ? "center" : "left"}>
      <Grid item>
        <Typography
          variant={isXs ? "h4" : "h2"}
          sx={{ fontWeight: 400, ml: isXs ? 0 : 1, mb: isXs ? 0 : 3 }}
        >
          For whom
        </Typography>
      </Grid>
      <Grid container justifyContent={isXs ? "center" : "left"}>
        <SocialMediaMenu />
        <SocialMediaContent />
      </Grid>
    </Grid>
  );
}

export default function SocialMediaIndex() {
  return (
    <SocialMediaContextProvider>
      <SocialMedia />
    </SocialMediaContextProvider>
  );
}
