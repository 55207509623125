import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useContext, useEffect, useState } from "react";
import PriceRepository from "../repository/PriceRepository";
import { Price } from "../model/price";
import { auth } from "../utils/firebaseUtil";
import { useNavigate } from "react-router-dom";
import ProductRepository from "../repository/ProductRepository";
import { Product } from "../model/product";
import { GlobalContext } from "../context/GlobalContext";
import { Button, CircularProgress, Slider } from "@mui/material";
import LoadingButton from "../common/LoadingButton";
import { toast } from "react-toastify";
import useBreakpoints from "../hooks/useBreakpoints";

enum BillingPeriod {
  MONTH = "month",
  YEAR = "year"
}

export default function Pricing() {
  const navigate = useNavigate();
  const { user, getStripeCheckoutUrl } = useContext(GlobalContext);
  const { isXs } = useBreakpoints();
  const [activeProducts, setActiveProducts] = useState<Product[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);
  const [loading, setLoading] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.MONTH);
  const [marks, setMarks] = useState<any[]>([]);
  const [currentSliderValue, setCurrentSliderValue] = useState(10);

  useEffect(() => {
    const getPrices = async () => {
      const ap = (await ProductRepository.getAllActive()).data;
      const p = (await PriceRepository.getAllByIds(ap.map((ap) => ap.id!))).data;
      setActiveProducts(ap);
      setPrices(p);
      const m = p
        .filter((price) => price.interval === "month")
        .map((price) => {
          const product = getCurrentProduct(price, ap);
          return {
            value: +product?.stripe_metadata_token!,
            label: +product?.stripe_metadata_token!
          };
        });
      setMarks(m);
    };
    getPrices().then();
  }, []);

  const getCurrentProduct = (price: Price, products: Product[]) => {
    return products.find((product) => product.id === price.product);
  };

  const openCustomerPortal = async (priceId: string, name: string) => {
    try {
      setLoading(true);
      const url = await getStripeCheckoutUrl(priceId, name);
      window.location.replace(url);
    } catch (e: any) {
      toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleValue = (event: Event, newValue: number | number[]) => {
    setCurrentSliderValue(newValue as number);
  };

  const getPrice = () => {
    const currentProducts = activeProducts.filter(
      (product) => +product.stripe_metadata_token! === currentSliderValue
    )!;
    const currentPrices = prices.filter((price) => price.interval === billingPeriod);
    const currentPrice = currentPrices.find((cp) =>
      currentProducts.map((product) => product.id).includes(cp.product)
    );
    return currentPrice;
  };

  return (
    <>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 4 }}>
        <Typography
          component="h1"
          variant={isXs ? "h4" : "h2"}
          align="center"
          color="text.primary"
          gutterBottom
        >
          Ready to get started?
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p" px={5}>
          Join over 10 million happy users.
        </Typography>
        {/* <Typography variant="body1" align="center" color="text.secondary" component="p" px={5} pt={1}>
          Billing: Save up to 25% with annual.
        </Typography>
        <Grid container alignItems="center" justifyContent="center" spacing={1} pt={1}>
          <Grid item onClick={() => setBillingPeriod(BillingPeriod.MONTH)}>
            <Typography>Monthly</Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={billingPeriod === BillingPeriod.YEAR}
              onChange={() =>
                billingPeriod === BillingPeriod.YEAR
                  ? setBillingPeriod(BillingPeriod.MONTH)
                  : setBillingPeriod(BillingPeriod.YEAR)
              }
              value={BillingPeriod.MONTH}
              color="default"
            />
          </Grid>
          <Grid item>Yearly</Grid>
        </Grid> */}
      </Container>
      <Container maxWidth="md" component="main" sx={{ pb: 5 }}>
        <Grid container spacing={5} alignItems="flex-start">
          {!prices || !getPrice() ? (
            <CircularProgress
              sx={{
                marginX: "auto",
                mt: "10vh"
              }}
            />
          ) : (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader
                    title="10 free tokens upon signing up"
                    subheader="For the hobbyist just getting started"
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{
                      align: "center"
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[700]
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        Free
                      </Typography>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => (auth.currentUser ? navigate("/") : navigate("/login"))}
                    >
                      Try free images
                    </Button>
                    <Box sx={{ maxWidth: 140, mx: "auto", mt: 2 }}>
                      <Typography variant="subtitle1" align="left">
                        • Try for free
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        • Upgrade anytime
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Card>
                  <CardHeader
                    title="Choose optimal tokens"
                    subheader="For bloggers & content marketers"
                    titleTypographyProps={{ align: "center" }}
                    action={<StarIcon color="warning" />}
                    subheaderTypographyProps={{
                      align: "center"
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[700]
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        €
                        {billingPeriod === BillingPeriod.MONTH
                          ? getPrice()?.unit_amount! / 100
                          : getPrice()?.unit_amount! / 1200}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /month
                      </Typography>
                    </Box>
                    <Box sx={{ width: "90%", mx: "auto" }}>
                      <Slider
                        step={null}
                        min={10}
                        max={1000}
                        marks={marks}
                        value={currentSliderValue}
                        onChange={handleValue}
                      />
                    </Box>
                    <Typography variant="h6" color="text.secondary" align="center" mb={2} mt={1}>
                      {currentSliderValue} tokens/month
                    </Typography>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        auth.currentUser
                          ? openCustomerPortal(
                              getPrice()?.id!,
                              getCurrentProduct(getPrice()!, activeProducts)?.name!
                            )
                          : navigate("/login?fromPricing=true");
                      }}
                      disabled={
                        getCurrentProduct(getPrice()!, activeProducts)?.name === user?.activeSubscription
                      }
                    >
                      {getCurrentProduct(getPrice()!, activeProducts)?.name === user?.activeSubscription
                        ? "Subscribed"
                        : "Subscribe"}
                    </LoadingButton>
                    <Box sx={{ maxWidth: 300, mx: "auto", mt: 2 }}>
                      <Typography variant="subtitle1" align="left">
                        • Unused credits rollover
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        • No watermark
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        • Email support
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        • Priority enhancement
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        • Upgrade, downgrade or cancel anytime
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
