const regName = /^[a-zA-Z ]+$/;
const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function validateName(name: string) {
  if (!regName.test(name)) {
    return "Invalid name";
  }
}

export function validateEmail(email: string) {
  if (!regEmail.test(email)) {
    return "Invalid email";
  }
}

export function validatePassword(password: string) {
  if (password.length < 8) {
    return "Password must contain at least 8 characters";
  }
}
