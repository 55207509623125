export const calculateResizedDimensions = (width: number, height: number, maxSize: number) => {
  if (width > maxSize || height > maxSize) {
    const aspectRatio = width / height;
    if (aspectRatio > 1) {
      return {
        width: Math.min(maxSize, width),
        height: Math.round((height * maxSize) / width / 2) * 2
      }
    } else {
      return {
        width: Math.round((width * maxSize) / height / 2) * 2,
        height: Math.min(maxSize, height)
      }
    }
  } else {
    return { width, height };
  }
}

export const loadImage = (src: string): any => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}
