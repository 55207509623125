import { Button, Grid, Tooltip, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useContext, useState } from "react";
import { DashboardContext, IMAGE_UPLOAD_LIMIT } from "./DashboardContext";
import Dropzone from "react-dropzone";
import { QueueContext } from "./QueueContext";
import useBreakpoints from "../../hooks/useBreakpoints";
import { track } from "@amplitude/analytics-browser";

export default function GetStartedView() {
  const { updateImages, updateImagesWithDrop, setGetStartedPage } = useContext(DashboardContext);
  const { queue } = useContext(QueueContext);
  const { isXs } = useBreakpoints();

  const [maxImageTooltip, setMaxImageTooltip] = useState<string>(
    window.localStorage.getItem("maxImageTooltip") || "true"
  );

  const removeTooltip = () => {
    window.localStorage.setItem("maxImageTooltip", "false");
    setMaxImageTooltip("false");
  };

  return (
    <Dropzone
      onDrop={(files) => {
        updateImagesWithDrop(files);
        setGetStartedPage(false);
        removeTooltip();
      }}
      accept={{
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": []
      }}
      noClick
    >
      {({ getRootProps, getInputProps }) => (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          {...getRootProps()}
          sx={{
            mt: 5,
            mx: isXs ? 1 : 5,
            mb: queue.length > 0 ? 1 : 5,
            border: "1px solid",
            borderColor: "border.secondary",
            borderRadius: 5,
            height: queue.length > 0 ? 278 : "60vh"
          }}
        >
          <Grid container justifyContent="center">
            <Grid
              item
              sx={{
                backgroundColor: "#f9faf9",
                borderRadius: 1000,
                px: 4,
                py: 3.7,
                my: 1
              }}
            >
              <FileUploadIcon sx={{ color: "#909091", fontSize: 60 }} />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="subtitle2" sx={{ my: 1 }}>
                Drag and drop image files to upload
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ my: 1 }}>
            <Grid item>
              <Tooltip
                open={maxImageTooltip === "true"}
                title={`Add up to ${IMAGE_UPLOAD_LIMIT} images for processing`}
                arrow
              >
                <Button
                  variant="contained"
                  component="label"
                  onClick={() => track("Select Files button clicked on get started view")}
                >
                  <input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    hidden
                    multiple
                    onChange={(event) => {
                      let files = event.target.files;
                      if (!files) return;
                      track("Images selected on get started view");
                      updateImages(files);
                      setGetStartedPage(false);
                      removeTooltip();
                    }}
                    {...getInputProps()}
                  />
                  Select files
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dropzone>
  );
}
