import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { createUserWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../utils/firebaseUtil";
import { Google } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography
} from "@mui/material";
import useBreakpoints from "../hooks/useBreakpoints";
import { validateEmail, validateName, validatePassword } from "../utils/validators";
import { toast } from "react-toastify";
import InlineError from "../common/InlineError";
import { Subscription } from "../model/subscription";
import { GlobalContext } from "../context/GlobalContext";
import UserRepository from "../repository/UserRepository";
import { User } from "../model/user";
import { track } from "@amplitude/analytics-browser";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://photoenhance.ai/">
        PhotoEnhance.ai
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Register() {
  const navigate = useNavigate();
  const { isXs } = useBreakpoints();
  const provider = new GoogleAuthProvider();
  const { saveUser, addFreeToken } = useContext(GlobalContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();

  const loginWithGoogle = async () => {
    track("Register with Popup");
    const userCredential = await signInWithPopup(auth, provider);
    const info = getAdditionalUserInfo(userCredential);
    const user: User = (await UserRepository.getById(auth.currentUser?.uid!)) as User;
    if (info && auth.currentUser && !user) {
      saveUser({
        id: auth.currentUser.uid,
        firstName: (info.profile?.given_name as string).trim(),
        lastName: (info.profile?.family_name as string).trim(),
        email: info.profile?.email as string,
        activeSubscription: Subscription.FREE
      });
      if (userCredential.user.emailVerified) {
        addFreeToken();
        toast.info("Thank you for signing up! You get free tokens to try out our platform.");
      }
    }
    await navigate("/");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    track("Register with Email and Password");
    try {
      if (credentialsAreValid()) {
        await createUserWithEmailAndPassword(auth, email, password);
        if (auth.currentUser) {
          saveUser({
            id: auth.currentUser.uid,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email,
            activeSubscription: Subscription.FREE
          });
        }
        await navigate("/");
        toast.info(
          "Thank you for signing up! Verify your email to get free tokens and try out our platform."
        );
      }
    } catch (e: any) {
      if (e.code === "auth/email-already-in-use") {
        toast.error("The email address is already in use");
      } else {
        toast.error("Something went wrong, please try again");
      }
    }
  };

  const credentialsAreValid = (): boolean => {
    const firstNameError = validateName(firstName);
    setFirstNameError(firstNameError);
    const lastNameError = validateName(lastName);
    setLastNameError(lastNameError);
    const emailError = validateEmail(email);
    setEmailError(emailError);
    const passwordError = validatePassword(password);
    setPasswordError(passwordError);
    return !firstNameError && !lastNameError && !emailError && !passwordError;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: isXs ? 3 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Button
          sx={{
            marginTop: 3,
            marginBottom: 2
          }}
          onClick={() => loginWithGoogle()}
          variant="contained"
          startIcon={<Google />}
        >
          Sign up with Google
        </Button>
        <Divider sx={{ p: 1, width: `100%` }} />
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName((e.target as HTMLInputElement).value)}
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                error={!!firstNameError}
                id="firstName"
                label="First Name"
                autoFocus
              />
              {firstNameError && <InlineError msg={firstNameError} />}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName((e.target as HTMLInputElement).value)}
                required
                fullWidth
                error={!!lastNameError}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
              {lastNameError && <InlineError msg={lastNameError} />}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                required
                fullWidth
                error={!!emailError}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              {emailError && <InlineError msg={emailError} />}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
                required
                fullWidth
                error={!!passwordError}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
              {passwordError && <InlineError msg={passwordError} />}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label={
                  <Typography variant="body2">
                    I want to receive inspiration, marketing promotions and updates via email.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={[firstName, lastName, email, password].includes("")}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <Link onClick={() => navigate("/login")} variant="body2" sx={{ cursor: "pointer" }}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ m: 5 }} />
    </Container>
  );
}
