import { Grid, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

export default function RestoreOptions() {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Removes yellow tones and makes colors natural
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" onClick={() => setShowTooltip(!showTooltip)} />
      </Tooltip>
    </Grid>
  );
}
