import { Box } from "@mui/material";
import { JOB_TYPE } from "../DashboardContext";
import ColorizeOptions from "./ColorizeOptions";
import DescratchOptions from "./DescratchOptions";
import EnhanceOptions from "./EnhanceOptions";
import LightenOptions from "./LightenOptions";
import RestoreOptions from "./RestoreOptions";
import UpscaleOptions from "./UpscaleOptions";
import Img2ImgOptions from "./Img2ImgOptions";
import Txt2ImgOptions from "./Txt2ImgOptions";

interface OptionsProps {
  readonly jobType: JOB_TYPE;
  readonly order: number;
}

export default function Options(props: OptionsProps) {
  const getOptions = () => {
    switch (props.jobType) {
      case JOB_TYPE.ENHANCE:
        return <EnhanceOptions order={props.order} />;
      case JOB_TYPE.DESCRATCH:
        return <DescratchOptions order={props.order} />;
      case JOB_TYPE.RESTORE:
        return <RestoreOptions />;
      case JOB_TYPE.COLORIZE:
        return <ColorizeOptions order={props.order} />;
      case JOB_TYPE.LIGHTEN:
        return <LightenOptions order={props.order} />;
      case JOB_TYPE.UPSCALE:
        return <UpscaleOptions order={props.order} />;
      case JOB_TYPE.IMG2IMG:
        return <Img2ImgOptions order={props.order} />;
      case JOB_TYPE.TXT2IMG:
        return <Txt2ImgOptions order={props.order} />;
    }
  };

  return <Box sx={{ minWidth: 120 }}>{getOptions()}</Box>;
}
