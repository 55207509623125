import { track } from "@amplitude/analytics-browser";
import { Button, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Background from "../../assets/banner/banner-bg.png";
import Background2 from "../../assets/banner/banner-bg2.svg";
import Ellipse1 from "../../assets/banner/ellipse1.png";
import Ellipse2 from "../../assets/banner/ellipse2.png";
import Ellipse3 from "../../assets/banner/ellipse3.png";
import Ellipse4 from "../../assets/banner/ellipse4.png";
import Ellipse5 from "../../assets/banner/ellipse5.png";
import Ellipse6 from "../../assets/banner/ellipse6.png";
import useBreakpoints from "../../hooks/useBreakpoints";
import { DashboardContext } from "../dashboard/DashboardContext";

export default function Banner() {
  const { isXs, isSm, isMd } = useBreakpoints();
  const navigate = useNavigate();
  const { updateImages, setGetStartedPage } = useContext(DashboardContext);

  return (
    <Grid
      container
      sx={{
        mx: isXs ? 0 : 5,
        mb: 12,
        backgroundColor: "primary.main",
        backgroundImage: `url(${Background}), url(${Background2})`,
        backgroundPosition: "center",
        backgroundSize: "auto 100%, 100% 100%",
        backgroundRepeat: "no-repeat",
        borderRadius: isXs ? 9 : 5,
        position: "relative",
        height: isXs ? 0 : undefined,
        width: isXs ? "100%" : undefined,
        paddingBottom: isXs ? "100%" : undefined
      }}
    >
      <Grid container sx={{ py: isXs ? 5 : 7 }}>
        <Typography
          variant={isXs || isSm ? "h4" : isMd ? "h3" : "h2"}
          color="secondary.main"
          sx={{
            fontWeight: 400,
            m: "auto",
            maxWidth: 680,
            textAlign: "center",
            px: isMd || isSm ? 15 : 3
          }}
        >
          Were we able to convince you to join us?
        </Typography>
      </Grid>
      <Grid container justifyContent="center" sx={{ pb: 8 }}>
        <Button
          variant="contained"
          component="label"
          sx={{
            backgroundColor: "secondary.main",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "card.secondary"
            }
          }}
          onClick={() => track("Get Started button clicked on banner card")}
        >
          Get Started
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            hidden
            multiple
            onChange={(event) => {
              let files = event.target.files;
              if (!files) return;
              track("Images selected on banner card");
              updateImages(files);
              setGetStartedPage(false);
              navigate("/login");
            }}
          />
        </Button>
      </Grid>
      <img
        src={Ellipse1}
        alt=""
        width={isXs || isSm ? 50 : 60}
        style={{
          position: "absolute",
          left: isXs ? "40%" : "15%",
          top: isXs ? "80%" : "50%"
        }}
      />
      <img
        src={Ellipse2}
        alt=""
        width={isXs || isSm ? 50 : 60}
        style={{ position: "absolute", left: "5%", top: isXs ? "40%" : "15%" }}
      />
      <img
        src={Ellipse3}
        alt=""
        width={isXs || isSm ? 50 : 60}
        style={{ position: "absolute", left: "7%", top: "75%" }}
      />
      {!isXs && (
        <img
          src={Ellipse4}
          alt=""
          width={isXs || isSm ? 50 : 60}
          style={{ position: "absolute", right: "5%", top: "15%" }}
        />
      )}
      <img
        src={Ellipse5}
        alt=""
        width={isXs || isSm ? 50 : 60}
        style={{
          position: "absolute",
          right: isXs ? "15%" : "7%",
          top: isXs ? "75%" : "80%"
        }}
      />
      <img
        src={Ellipse6}
        alt=""
        width={isXs || isSm ? 50 : 60}
        style={{
          position: "absolute",
          right: isXs ? "10%" : "15%",
          top: isXs ? "45%" : "53%"
        }}
      />
    </Grid>
  );
}
