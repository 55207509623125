import { Button, Grid } from "@mui/material";
import LogoSVG from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { auth } from "../../utils/firebaseUtil";
import MenuButtons from "./MenuButtons";
import useBreakpoints from "../../hooks/useBreakpoints";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AccountMenu from "../AccountMenu";
import { track } from "@amplitude/analytics-browser";

export default function Navbar() {
  const navigate = useNavigate();
  const { isXs, isSm, isLg } = useBreakpoints();
  return (
    <Grid
      container
      sx={{
        borderBottom: "1px solid",
        borderColor: "border.primary",
        minHeight: 70,
        position: !isXs ? "sticky" : null,
        top: 0,
        zIndex: 2,
        backgroundColor: "secondary.main"
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1180,
          margin: "auto"
        }}
      >
        <Grid container justifyContent="space-between" sx={{ mx: isXs ? 1 : 5, alignItems: "center" }}>
          <Grid item onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
            <img src={LogoSVG} alt="" width={isXs ? 190 : 217} />
          </Grid>
          {isLg && !auth.currentUser && <MenuButtons />}
          {auth.currentUser ? (
            <Grid item>
              <AccountMenu />
            </Grid>
          ) : (
            !isXs && (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    track("Login button clicked on navbar");
                    navigate("/login");
                  }}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    track("Get Started button clicked on navbar");
                    navigate("/register");
                  }}
                >
                  Get Started
                </Button>
              </Grid>
            )
          )}
          {!auth.currentUser &&
            (isSm || isXs) &&
            (window.location.pathname === "/menu" ? (
              <CloseRoundedIcon onClick={() => navigate("/")} />
            ) : (
              <MenuIcon onClick={() => navigate("/menu")} />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
