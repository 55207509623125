import { auth } from "../utils/firebaseUtil";
import { fromURL } from "image-resize-compress";
import { getIdToken } from "firebase/auth";
import { Job, JOB_TYPE } from "../components/dashboard/DashboardContext";
import { track } from "@amplitude/analytics-browser";
import { calculateResizedDimensions, loadImage } from "./PhotoHandler";

let serverUrl = process.env.REACT_APP_SERVER_URL;

const getMaxSizeByJobType = (jobType: JOB_TYPE) => {
  return jobType === JOB_TYPE.ENHANCE ? 2000 : 1500;
};

export const photoProcess = async (photoFile: string, job: Job) => {
  const maxSize = getMaxSizeByJobType(job.type);
  const img = await loadImage(photoFile);
  const { width, height } = calculateResizedDimensions(img.width, img.height, maxSize);
  const photo = await fromURL(photoFile, 100, width, height, "jpeg");
  return sendPhotoToProcess(photo, job);
};

const getCurrentOptions = (job: Job) => {
  switch (job.type) {
    case JOB_TYPE.ENHANCE:
      return {
        background: job.options.background,
        realistic: job.options.realistic
      };
    case JOB_TYPE.COLORIZE:
      return {
        vibrant: job.options.vibrant,
        filter: job.options.filter,
        intensity: job.options.intensity,
        brightness: job.options.brightness
      };
    case JOB_TYPE.DESCRATCH:
      return {
        hr: job.options.hr
      };
    case JOB_TYPE.LIGHTEN:
      return {
        lighten_type: job.options.lightenType
      };
    case JOB_TYPE.UPSCALE:
      return {
        prompt: job.options.prompt
      };
    case JOB_TYPE.TXT2IMG:
      return {
        prompt: job.options.prompt
      };
    case JOB_TYPE.IMG2IMG:
      return {
        prompt: job.options.prompt,
        prompt_negative: job.options.promptNegative,
        strength: job.options.strength
      };
    default:
      return {};
  }
};

const sendPhotoToProcess = async (photoFile: Blob, job: Job) => {
  track("Send Photo To Process", { job_type: job.type, job_options: job.options });
  let data = new FormData();
  data.append("photo_file", photoFile);
  data.append("job_type", job.type);
  data.append("options", JSON.stringify(getCurrentOptions(job)));
  data.append("device_id", auth.currentUser?.email as string);
  data.append("app_id", "web");

  let idToken = await getIdToken(auth.currentUser!);

  const response = await fetch(`${serverUrl}/api/v2/send-photo-to-process`, {
    method: "POST",
    headers: { Authorization: idToken, mode: "no-cors" },
    cache: "no-cache",
    body: data
  });

  return await response.json();
};

export const checkJobStatus = async (jobId: any) => {
  const response = await fetch(`${serverUrl}/api/v2/check-job-status?job_id=${jobId}`);
  return await response.json();
};

export const getProcessedResult = async (jobId: any) => {
  track("Get Processed Result", { job_id: jobId });
  let idToken = await getIdToken(auth.currentUser!);
  const response = await fetch(`${serverUrl}/api/v2/get-processed-result-web?job_id=${jobId}`, {
    headers: { Authorization: idToken }
  });
  return await response.json();
};
