import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

export interface LoadingButtonProps extends ButtonProps {
  readonly loading?: boolean;
  readonly buttonRef?: React.RefObject<HTMLButtonElement>;
}

export default function LoadingButton({ loading, buttonRef, ...props }: LoadingButtonProps) {
  return (
    <Button
      {...props}
      ref={buttonRef}
      startIcon={loading ? <CircularProgress size={14} /> : props.startIcon}
      disabled={props.disabled || loading}
    >
      {props.children}
    </Button>
  );
}
