import { Button, Grid, Typography } from "@mui/material";
import BenefitsCard from "./BenefitsCard";
import LayerIcon from "../../assets/layer-icon.svg";
import ScaleIcon from "../../assets/scale-icon.svg";
import PicsIcon from "../../assets/pics-icon.svg";
import SpeedIcon from "../../assets/speed-icon.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import Background from "../../assets/banner/banner-bg.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DashboardContext } from "../dashboard/DashboardContext";
import { track } from "@amplitude/analytics-browser";

export default function Benefits() {
  const { isXs } = useBreakpoints();
  const navigate = useNavigate();
  const { updateImages, setGetStartedPage } = useContext(DashboardContext);

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        backgroundColor: "primary.main",
        backgroundImage: !isXs ? `url(${Background})` : null,
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        borderRadius: isXs ? 10 : 5,
        pt: 3.5,
        pb: isXs ? 1.5 : 3.5,
        px: isXs ? 2 : 3.5,
        my: isXs ? 7 : 15,
        mx: isXs ? 0 : 5
      }}
    >
      <Grid item>
        <Typography
          variant={isXs ? "h4" : "h3"}
          sx={{
            pb: 3,
            pl: isXs ? 1 : 0,
            maxWidth: isXs ? "auto" : 450,
            color: "secondary.main"
          }}
        >
          Thanks to perfect artificial intelligence systems
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "secondary.main",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "card.secondary"
            },
            fontSize: 16,
            px: 6,
            py: 2,
            ml: isXs ? 1 : 0,
            mb: 2
          }}
          component="label"
          onClick={() => track("Try now button clicked on benefits card")}
        >
          Try now
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            hidden
            multiple
            onChange={(event) => {
              let files = event.target.files;
              if (!files) return;
              track("Images selected on benefits card");
              updateImages(files);
              setGetStartedPage(false);
              navigate("/login");
            }}
          />
        </Button>
      </Grid>
      <Grid item>
        <Grid container>
          <BenefitsCard
            title="Simultaneous processing"
            description="Enhance multiple photos at the same time"
            icon={LayerIcon}
            expanded
          />
          <BenefitsCard
            title="Improve high-resolution photos"
            description="Works for photos up to 2K megapixels"
            icon={ScaleIcon}
          />
        </Grid>
        <Grid container>
          <BenefitsCard
            title="Chain processing"
            description="Enhance, colorize, lighten in any sequence you want"
            icon={PicsIcon}
          />
          <BenefitsCard
            title="Fast &amp; Simple"
            description="Improve photo with one click of a button"
            icon={SpeedIcon}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
