export enum Subscription {
  FREE = "Free",
  TEN_MONTHLY = "10 monthly",
  HUNDRED_MONTHLY = "100 monthly",
  TWO_HUNDRED_MONTHLY = "200 monthly",
  FIVE_HUNDRED_MONTHLY = "500 monthly",
  THOUSAND_MONTHLY = "1000 monthly",
  TEN_YEARLY = "10 yearly",
  HUNDRED_YEARLY = "100 yearly",
  TWO_HUNDRED_YEARLY = "200 yearly",
  FIVE_HUNDRED_YEARLY = "500 yearly",
  THOUSAND_YEARLY = "1000 yearly"
}
