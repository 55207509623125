import { track } from "@amplitude/analytics-browser";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
import { titleCase } from "../../utils/stringConverter";
import { DashboardContext, JOB_TYPE, activeJobTypes } from "./DashboardContext";

interface SelectProps {
  readonly order: number;
}

export default function JobSelect(props: SelectProps) {
  const { jobs, updateJobs } = useContext(DashboardContext);

  const handleChange = (event: SelectChangeEvent<JOB_TYPE>) => {
    track(`Process changed to ${event.target.value}`);
    updateJobs({
      type: event.target.value as JOB_TYPE,
      order: props.order,
      options: {}
    });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">Process #{props.order + 1}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={jobs[props.order]?.type || ""}
          label={`Process #${props.order + 1}`}
          onChange={handleChange}
        >
          {Object.values(JOB_TYPE)
            .filter((jobType) => activeJobTypes.includes(jobType))
            .filter(
              (jobType) =>
                !jobs
                  .filter((job) => job.order !== props.order)
                  .map((job) => job.type)
                  .includes(jobType)
            )
            .map((job) => (
              <MenuItem key={job} value={job}>
                {titleCase(job.replace(/_/g, " ")).replace("Photo ", "").split(/(\s+)/)[0]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
