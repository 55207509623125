import { httpsCallable } from "firebase/functions";
import { createContext, ReactNode, useEffect, useState } from "react";
import { User } from "../model/user";
import UserRepository from "../repository/UserRepository";
import { auth, functions } from "../utils/firebaseUtil";
import { setUserId } from "@amplitude/analytics-browser";

export interface GlobalContextValue {
  readonly user: User | null;
  readonly getStripeCustomerPortalUrl: () => Promise<string>;
  readonly sendForgotPasswordEmail: (email: string) => void;
  readonly getStripeCheckoutUrl: (priceId: string, subscriptionName: string) => Promise<string>;
  readonly saveUser: (user: User) => void;
  readonly addFreeToken: () => void;
}

interface ProviderProps {
  children: ReactNode;
}

export const GlobalContext = createContext<GlobalContextValue>({} as any);

export const GlobalContextProvider = (props: ProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const getCustomerPortalUrl = httpsCallable(functions, "getCustomerPortalUrl");
  const forgotPassword = httpsCallable(functions, "forgotPassword");
  const getCheckoutUrl = httpsCallable(functions, "getCheckoutUrl");
  const saveUserCallable = httpsCallable(functions, "saveUser");
  const addFreeTokenCallable = httpsCallable(functions, "addFreeToken");

  useEffect(() => {
    if (auth.currentUser?.uid) {
      UserRepository.getByIdLive(auth.currentUser.uid, (user) => {
        if (user) {
          setUser(user);
        }
      });
    } else {
      setUser(null);
    }
    setUserId(auth.currentUser?.uid);
  }, [auth?.currentUser?.uid]);

  const getStripeCustomerPortalUrl = async () => {
    return (await getCustomerPortalUrl({ domain: window.location.origin })).data as string;
  };

  const sendForgotPasswordEmail = async (email: string) => {
    await forgotPassword({ email, domain: window.location.origin });
  };

  const getStripeCheckoutUrl = async (priceId: string, subscriptionName: string) => {
    return (await getCheckoutUrl({ priceId, subscriptionName, domain: window.location.origin }))
      .data as string;
  };

  const saveUser = async (user: User) => {
    await saveUserCallable(user);
  };

  const addFreeToken = async () => {
    await addFreeTokenCallable();
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        getStripeCustomerPortalUrl,
        sendForgotPasswordEmail,
        getStripeCheckoutUrl,
        saveUser,
        addFreeToken
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
