import { Grid, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import EnhancerBefore from "../../assets/home/Enhancer Before.webp";
import EnhancerAfter from "../../assets/home/Enhancer After.webp";
import BeforeAfterIcon from "../../assets/before-after-icon.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import DragAndDropView from "../../common/DragAndDropView";

export default function MainCard() {
  const { isXs } = useBreakpoints();

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        backgroundColor: "card.primary",
        borderRadius: isXs ? 10 : 5,
        p: isXs ? 2 : 5,
        my: 5,
        mx: !isXs ? 5 : 0,
      }}
    >
      <Grid item>
        <Grid
          item
          sx={{
            backgroundColor: "secondary.main",
            borderRadius: 100,
            width: 100,
            p: 1,
            mb: 3,
            mx: isXs ? "auto" : null,
          }}
        >
          <CircleIcon sx={{ fontSize: 10, color: "dot.primary", mx: 0.5 }} />
          <Typography variant="body2" display="inline">
            Bestseller
          </Typography>
        </Grid>
        {isXs && (
          <Grid
            container
            sx={{
              my: 2,
              borderRadius: isXs ? 8 : 4,
              width: isXs ? "auto" : 400,
              height: isXs ? 340 : 465,
              overflow: "hidden",
            }}
          >
            <ReactBeforeSliderComponent
              firstImage={{
                imageUrl: EnhancerAfter,
              }}
              secondImage={{
                imageUrl: EnhancerBefore,
              }}
              delimiterIconStyles={{
                width: 40,
                height: 40,
                backgroundImage: `url(${BeforeAfterIcon})`,
              }}
              withResizeFeel
            />
          </Grid>
        )}
        <Typography
          variant={isXs ? "h4" : "h3"}
          sx={{
            pb: 2,
            maxWidth: isXs ? "auto" : 450,
            textAlign: isXs ? "center" : "left",
          }}
        >
          AI Image Enhancer &amp; Image Enlarger
        </Typography>
        <Typography
          variant="body1"
          sx={{
            pb: 3,
            color: "text.secondary",
            maxWidth: isXs ? "auto" : 450,
            textAlign: isXs ? "center" : "left",
          }}
        >
          Powerful AI editor to increase image resolution without quality loss
          and enhance photos in seconds
        </Typography>
        <DragAndDropView />
      </Grid>
      {!isXs && (
        <Grid
          container
          sx={{
            borderRadius: 4,
            width: isXs ? "auto" : 400,
            height: isXs ? 320 : 465,
            overflow: "hidden",
          }}
        >
          <ReactBeforeSliderComponent
            firstImage={{
              imageUrl: EnhancerBefore,
            }}
            secondImage={{
              imageUrl: EnhancerAfter,
            }}
            delimiterIconStyles={{
              width: 40,
              height: 40,
              backgroundImage: `url(${BeforeAfterIcon})`,
            }}
            withResizeFeel
          />
        </Grid>
      )}
    </Grid>
  );
}
