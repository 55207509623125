import { Chip, Grid, Tooltip } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { createRef, useEffect, useState } from "react";
import { JOB_TYPE } from "../DashboardContext";
import PlaceholderImg from "../../../assets/placeholder.png";
import { QueueItem } from "../QueueContext";
import { getChipLabel } from "../../../utils/stringConverter";
import TimelineDialog from "./TimelineDialog";
import { track } from "@amplitude/analytics-browser";

interface TimelineElementProps {
  readonly jobType: JOB_TYPE;
  readonly image: string;
  readonly processedImages: string[];
  readonly index: number;
  readonly width: number;
  readonly prevJobType?: JOB_TYPE;
}

function TimelineElement(props: TimelineElementProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = (_: any, reason: any) => {
    if (reason && reason === "backdropClick") {
      setOpenDialog(false);
    }
  };

  return (
    <>
      <ArrowRightAltIcon
        preserveAspectRatio="none"
        sx={{
          width: 70,
          height: 60,
          color: "border.primary",
          marginBottom: 2
        }}
      />
      <Grid>
        <img
          src={props.processedImages[props.index] || PlaceholderImg}
          alt=""
          height={60}
          width={props.width || "auto"}
          style={{
            borderRadius: 5,
            cursor: "pointer",
            display: "block",
            margin: "auto",
            border: !props.processedImages[props.index] ? "1px solid #DEE2E6" : "1px solid transparent"
          }}
          onClick={() => {
            track("Image is enlarged on image timeline");
            setOpenDialog(true);
          }}
        />
        <Chip
          label={getChipLabel(props.jobType)}
          size="small"
          sx={{
            display: "block",
            mx: "auto",
            pt: 0.5,
            textAlign: "center",
            mt: 1.5,
            mb: 1,
            backgroundColor: "primary.main",
            color: "secondary.main"
          }}
        />
      </Grid>
      {props.processedImages[props.index] && (
        <TimelineDialog
          jobName={getChipLabel(props.jobType)}
          image={props.processedImages[props.index]}
          secondJobName={props.prevJobType ? getChipLabel(props.prevJobType) : "Original"}
          secondImage={props.index === 0 ? props.image : props.processedImages[props.index - 1]}
          isOpen={openDialog}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface ImageTimelineProps {
  readonly image: string;
  readonly processedImages: string[];
  readonly queueItem: QueueItem;
}

export default function ImageTimeline(props: ImageTimelineProps) {
  const ref = createRef<any>();
  const [width, setWidth] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [showProcessingTooltip, setShowProcessingTooltip] = useState<string>(
    window.localStorage.getItem("processing_tooltip") || "true"
  );

  const removeTooltip = () => {
    window.localStorage.setItem("processing_tooltip", "false");
    setShowProcessingTooltip("false");
  };

  useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, [ref]);

  return (
    <Grid container>
      <Grid>
        <Tooltip
          open={showProcessingTooltip === "true"}
          arrow
          placement="top-start"
          title="Enlarge photos by clicking on them"
        >
          <img
            ref={ref}
            src={props.image}
            alt=""
            height={60}
            style={{
              borderRadius: 5,
              border: "1px solid transparent",
              cursor: "pointer",
              display: "block",
              margin: "auto"
            }}
            onClick={() => {
              track("Original image is enlarged on image timeline");
              setOpenDialog(true);
              removeTooltip();
            }}
          />
        </Tooltip>
        <Chip
          label="Original"
          size="small"
          sx={{
            display: "block",
            mx: "auto",
            pt: 0.5,
            textAlign: "center",
            mt: 1.5,
            mb: 1,
            backgroundColor: "primary.main",
            color: "secondary.main"
          }}
        />
      </Grid>
      {props.queueItem.jobs.map((job, index) => (
        <TimelineElement
          key={index}
          jobType={job.type}
          image={props.image}
          processedImages={props.processedImages}
          index={index}
          width={width}
          prevJobType={index > 0 ? props.queueItem.jobs[index - 1].type : undefined}
        />
      ))}
      <TimelineDialog
        jobName="Original"
        image={props.image}
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Grid>
  );
}
