import { Grid } from "@mui/material";
import Mobile1 from "../../../../assets/socialmedia/printing-services1-mobile.webp";
import Mobile2 from "../../../../assets/socialmedia/printing-services2-mobile.webp";
import Web1 from "../../../../assets/socialmedia/printing-services1-web.webp";
import Web2 from "../../../../assets/socialmedia/printing-services2-web.webp";
import Web3 from "../../../../assets/socialmedia/printing-services3-web.webp";
import useBreakpoints from "../../../../hooks/useBreakpoints";

export default function PrintingServicesContent() {
  const { isXs } = useBreakpoints();

  return (
    <Grid
      container={isXs}
      justifyContent={"center"}
      sx={{ position: "relative" }}
    >
      <img
        src={isXs ? Mobile1 : Web1}
        alt=""
        width={isXs ? "100%" : 630}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          left: 30,
          top: 20,
          borderRadius: 24,
        }}
      />
      <img
        src={isXs ? Mobile2 : Web2}
        alt=""
        width={isXs ? "100%" : 240}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          left: 40,
          top: 300,
          marginTop: isXs ? 15 : 0,
          borderRadius: isXs ? 24 : 200,
        }}
      />
      {!isXs && (
        <img
          src={Web3}
          alt=""
          width={isXs ? "100%" : 325}
          style={{
            objectFit: "cover",
            position: isXs ? "static" : "absolute",
            left: 332,
            top: 300,
            borderRadius: 24,
          }}
        />
      )}
    </Grid>
  );
}
