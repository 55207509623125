import { Firestore } from "firebase/firestore";
import * as firestore from "firebase/firestore";
import { db } from "../utils/firebaseUtil";
import { GetManyResult, toEntities } from "./Common";
import { CrudRepository } from "./CrudRepository";
import { last } from "lodash";
import { Collection } from "../model/collections";
import { User } from "../model/user";

class UserRepository extends CrudRepository<User> {
  constructor(db: Firestore) {
    super(db, Collection.USERS);
  }

  public getAll = async (): Promise<GetManyResult<User>> => {
    const query = firestore.query(
      firestore.collection(this.db, this.collection)
    );

    const snapshot = await firestore.getDocs(query);
    return {
      data: toEntities<User>(snapshot.docs),
      lastDoc: last(snapshot.docs)
    };
  };
}

export default new UserRepository(db);
