import { FormGroup, Grid, Slider, Stack, TextField, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../DashboardContext";

interface Img2ImgOptionsProps {
  readonly order: number;
}

export default function Img2ImgOptions(props: Img2ImgOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [prompt, setPrompt] = useState("A realistic photo of a person");
  const [promptNegative, setPromptNegative] = useState("bad, deformed, ugly, bad anatomy");
  const [strength, setStrength] = useState<number>(50);

  useEffect(() => {
    updateOptions(props.order, {
      prompt: prompt,
      promptNegative: promptNegative,
      strength: strength / 100.0
    });
  }, [prompt, promptNegative, strength]);

  const handleStrength = (event: Event, newValue: number | number[]) => {
    setStrength(newValue as number);
  };

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Removes scratches from old photos
            <br />
            &#x2022;&nbsp; Improves photo's structure
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
      <FormGroup>
        <TextField
          id="standard-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          variant="standard"
        />
        <TextField
          id="standard-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={4}
          value={promptNegative}
          onChange={(e) => setPromptNegative(e.target.value)}
          variant="standard"
        />
      </FormGroup>
      <Stack spacing={2} direction="row" sx={{ mb: 0.3 }} alignItems="center">
        <Typography variant="body2" sx={{ mr: 1.8 }}>
          Intensity
        </Typography>
        <Slider aria-label="Intensity" value={strength} onChange={handleStrength} />
        <Typography variant="body2" sx={{ minWidth: 15 }}>
          {strength}
        </Typography>
      </Stack>
    </Grid>
  );
}
