import { Grid } from "@mui/material";
import { useContext } from "react";
import { DashboardContext } from "../DashboardContext";
import JobSelect from "../JobSelect";
import Options from "./Options";

interface JobProps {
  readonly order: number;
}

export default function Job(props: JobProps) {
  const { jobs } = useContext(DashboardContext);

  return (
    <Grid item>
      <JobSelect order={props.order} />
      {jobs[props.order] && (
        <Grid item>
          <Options jobType={jobs[props.order].type} order={props.order} />
        </Grid>
      )}
    </Grid>
  );
}
