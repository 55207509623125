import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../DashboardContext";

interface ColorizeOptionsProps {
  readonly order: number;
}

export default function ColorizeOptions(props: ColorizeOptionsProps) {
  const { updateOptions } = useContext(DashboardContext);
  const [vibrant, setVibrant] = useState(false);
  const [filter, setFilter] = useState(false);
  const [intensity, setIntensity] = useState<number>(60);
  const [brightness, setBrightness] = useState<number>(3);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    updateOptions(props.order, {
      vibrant,
      filter,
      intensity,
      brightness
    });
  }, [vibrant, filter, intensity, brightness]);

  const handleIntensity = (event: Event, newValue: number | number[]) => {
    setIntensity(newValue as number);
  };

  const handleBrightness = (event: Event, newValue: number | number[]) => {
    setBrightness(newValue as number);
  };

  return (
    <Grid item sx={{ my: 1 }}>
      <Tooltip
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        arrow
        title={
          <Typography variant="body2" p={0.5}>
            &#x2022;&nbsp; Adds bright colors to your photo
            <br />
            &#x2022;&nbsp; Works for black &amp; white images
            <br />
            &#x2022;&nbsp; Use photos with fully visible faces
          </Typography>
        }
        placement="right"
      >
        <InfoOutlinedIcon fontSize="small" color="action" onClick={() => setShowTooltip(!showTooltip)} />
      </Tooltip>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={vibrant} onChange={() => setVibrant(!vibrant)} />}
          label={<Typography variant="subtitle2">Vibrant</Typography>}
        />
        <FormControlLabel
          control={<Checkbox checked={filter} onChange={() => setFilter(!filter)} />}
          label={<Typography variant="subtitle2">Filter</Typography>}
          sx={{ mt: -1.2 }}
        />
      </FormGroup>
      <Stack spacing={2} direction="row" sx={{ mb: 0.3 }} alignItems="center">
        <Typography variant="body2" sx={{ mr: 1.8 }}>
          Intensity
        </Typography>
        <Slider aria-label="Intensity" value={intensity} onChange={handleIntensity} />
        <Typography variant="body2" sx={{ minWidth: 15 }}>
          {intensity}
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <Typography variant="body2">Brightness</Typography>
        <Slider
          aria-label="Brightness"
          value={brightness}
          step={1}
          marks
          min={0}
          max={5}
          onChange={handleBrightness}
        />
        <Typography variant="body2" sx={{ minWidth: 15 }}>
          {brightness}
        </Typography>
      </Stack>
    </Grid>
  );
}
