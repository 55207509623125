import { Grid, Typography } from "@mui/material";

interface StatisticProps {
  readonly title: string;
  readonly description: string;
}

function Statistic(props: StatisticProps) {
  return (
    <Grid item>
      <Typography variant="h4" sx={{ color: "primary.main", mb: 2, textAlign: "center" }}>
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          width: 280,
          textAlign: "center",
          m: "auto"
        }}
      >
        {props.description}
      </Typography>
    </Grid>
  );
}

export default function Statistics() {
  return (
    <Grid container justifyContent={"space-between"} my={12} mx={5}>
      <Statistic
        title="4M happy users"
        description="Constant updates and support by experienced developers"
      />
      <Statistic title="20M photos enhanced" description="Highest quality improvement in seconds" />
      <Statistic title="4.6 out of 5 rating" description="Estimated by >70K users" />
    </Grid>
  );
}
