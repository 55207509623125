import { track } from "@amplitude/analytics-browser";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FooterLogo from "../../assets/footer-logo.png";
import useBreakpoints from "../../hooks/useBreakpoints";
import MenuButtons from "./MenuButtons";

export default function Footer() {
  const { isXs, isSm } = useBreakpoints();
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        marginTop: "auto",
        height: isXs ? "auto" : 170,
        borderRadius: isXs ? 8 : "48px 48px 0px 0px",
        backgroundColor: "footer.primary"
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{
          maxWidth: 1180,
          mx: "auto"
        }}
      >
        <Grid
          container
          justifyContent={isXs ? "center" : "space-between"}
          sx={{ mx: isXs || isSm ? 2 : 5, mt: 2, mb: -3, alignItems: "center" }}
        >
          <Grid
            item
            onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
            sx={{ cursor: "pointer" }}
          >
            <img src={FooterLogo} alt="" width={isSm ? 178 : 217} />
          </Grid>
          <MenuButtons isWhite />
        </Grid>
        <Grid
          container
          justifyContent={isXs ? "center" : "space-between"}
          sx={{
            mx: isXs || isSm ? 2 : 5,
            mt: isXs ? 4 : 0,
            mb: isXs ? 2 : 0,
            alignItems: "center"
          }}
        >
          <Typography variant="body2" sx={{ color: "footer.secondary" }}>
            ©PhotoEnhance, Inc. All right reserved.
          </Typography>
          <Grid item>
            <Button
              variant="text"
              sx={{ color: "footer.secondary" }}
              onClick={() => {
                track("Terms button clicked on footer");
                navigate("/terms");
              }}
            >
              Terms
            </Button>
            <Button
              variant="text"
              sx={{ color: "footer.secondary" }}
              onClick={async () => {
                track("Contact us button clicked on footer");
                await navigate("/terms");
                window.scrollTo({ top: 999999, behavior: "smooth" });
              }}
            >
              Contact us
            </Button>
            <Button
              variant="text"
              sx={{ color: "footer.secondary" }}
              onClick={() => {
                track("Privacy policy button clicked on footer");
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
