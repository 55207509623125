import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
import ImageTimeline from "./ImageTimeline";
import { checkJobStatus, getProcessedResult, photoProcess } from "../../../api/Api";
import { DashboardContext, Job } from "../DashboardContext";
import { QueueContext, QueueItem } from "../QueueContext";
import { track } from "@amplitude/analytics-browser";
import useBreakpoints from "../../../hooks/useBreakpoints";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "99%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ borderRadius: 100 }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

enum ProcessStatus {
  PROCESSING,
  CANCELLED,
  FAILED,
  FINISHED
}

interface ProgressBarProps {
  readonly image: string;
  readonly queueItem: QueueItem;
}

export default function ProgressBar(props: ProgressBarProps) {
  const [percent, setPercent] = useState(0);
  const [processedImages, setProcessedImages] = useState<string[]>([]);
  const [status, setStatus] = useState(ProcessStatus.PROCESSING);
  const { setFinalImages, setIntermediaryImages, saveImage } = useContext(QueueContext);
  const { bringJobBack, setGetStartedPage } = useContext(DashboardContext);
  const [errorMsg, setErrorMsg] = useState("");
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const isInitialMount = useRef(true);
  const { isXs } = useBreakpoints();

  useEffect(() => {
    fetchData(props.image, props.queueItem.jobs[0]).then();
  }, []);

  function setStatusAndFireEvent(status: ProcessStatus) {
    setStatus(status);
    track("Process Status Changed", { status: status });
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (props.queueItem.jobs.length > processedImages.length) {
        fetchData(
          processedImages[processedImages.length - 1],
          props.queueItem.jobs[processedImages.length]
        ).then();
      } else {
        if (status !== ProcessStatus.FAILED) {
          setStatusAndFireEvent(ProcessStatus.FINISHED);
          setFinalImages((prev) => [...prev, processedImages[processedImages.length - 1]]);
        }
      }
    }
  }, [processedImages]);

  const fetchData = async (image: string, job: Job) => {
    const { job_id } = await photoProcess(image, job);
    intervalRef.current = setInterval(async function () {
      const responseData = await checkJobStatus(job_id);
      setPercent(responseData.percent);
      if (responseData.percent === 100) {
        const result = await getProcessedResult(job_id);
        setProcessedImages([...processedImages, result.processed_photo_url]);
        setIntermediaryImages((prev) => [...prev, result.processed_photo_url]);
        clearTimer();
      }
      if (responseData.status < 0) {
        setStatusAndFireEvent(ProcessStatus.FAILED);
        setErrorMsg(`Job failed: ${responseData.status}`);
        clearTimer();
      }
    }, 1000);
  };

  const clearTimer = () => {
    clearInterval(intervalRef.current as NodeJS.Timer);
    intervalRef.current = null;
  };

  const statusIsCancelled = () => {
    return [ProcessStatus.CANCELLED].includes(status);
  };

  const getButtonText = () => {
    switch (status) {
      case ProcessStatus.PROCESSING:
        return "Cancel";
      case ProcessStatus.CANCELLED:
        return "Cancelled";
      case ProcessStatus.FINISHED:
        return "Download";
      case ProcessStatus.FAILED:
        return "Retry";
    }
  };

  return (
    <Grid
      container
      sx={{
        mx: isXs ? 1 : 5,
        my: 1,
        border: "1px solid",
        borderColor: "border.secondary",
        borderRadius: 5,
        px: isXs ? 3 : 5,
        py: 3,
        alignItems: "center"
      }}
    >
      <Box sx={{ width: "100%" }}>
        <ImageTimeline image={props.image} processedImages={processedImages} queueItem={props.queueItem} />
        <LinearProgressWithLabel value={percent} />
      </Box>
      <Button
        variant={statusIsCancelled() ? "text" : "outlined"}
        sx={{
          mt: 1,
          color: statusIsCancelled() ? "red" : undefined,
          cursor: statusIsCancelled() ? "default" : "cursor",
          "&:hover": {
            background: statusIsCancelled() ? "none" : undefined
          }
        }}
        disableRipple={statusIsCancelled()}
        onClick={() => {
          track(`${getButtonText()} button clicked on progress bar`);
          status === ProcessStatus.PROCESSING && setStatusAndFireEvent(ProcessStatus.CANCELLED);
          status === ProcessStatus.FINISHED && saveImage(processedImages[processedImages.length - 1]);
          if (status === ProcessStatus.FAILED) {
            bringJobBack(props.image, props.queueItem.jobs);
            setGetStartedPage(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
          clearTimer();
        }}
      >
        {getButtonText()}
      </Button>
      <Typography variant="caption" color="error" ml={1} mt={1}>
        {errorMsg}
      </Typography>
    </Grid>
  );
}
