import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { activeJobTypes, DashboardContext, IMAGE_UPLOAD_LIMIT, JOB_TYPE } from "./DashboardContext";
import Job from "./select/Job";
import useBreakpoints from "../../hooks/useBreakpoints";
import { QueueContext } from "./QueueContext";
import { track } from "@amplitude/analytics-browser";

export default function MultiselectView() {
  const { isXs, isSm } = useBreakpoints();
  const { images, jobs, updateImages, updateJobs, clearImages, removeImage, clearJobs, setGetStartedPage } =
    useContext(DashboardContext);
  const { queue, setQueue } = useContext(QueueContext);
  const [showTooltip, setShowTooltip] = useState<string>(window.localStorage.getItem("tooltip") || "true");
  const [showChainProcessingTooltip, setShowChainProcessingTooltip] = useState<string>(
    window.localStorage.getItem("tooltip") || "true"
  );

  const removeTooltip = () => {
    window.localStorage.setItem("tooltip", "false");
    setShowTooltip("false");
  };

  const removeChainProcessingTooltip = () => {
    window.localStorage.setItem("tooltip", "false");
    setShowChainProcessingTooltip("false");
  };

  const goToGetStartedPage = () => {
    setGetStartedPage(true);
    clearImages();
    clearJobs();
  };

  const getJobTypeThatIsNotPresentYet = (): JOB_TYPE => {
    return Object.values(JOB_TYPE)
      .filter((jobType) => activeJobTypes.includes(jobType))
      .filter((jobType) => !jobs.map((job) => job.type as string).includes(jobType))[0];
  };

  useEffect(() => {
    if (queue.length > 0) {
      removeTooltip();
    }
  }, [queue]);

  useEffect(() => {
    images.length === 0 && goToGetStartedPage();
  }, [images]);

  return (
    <Grid
      container
      sx={{
        mx: isXs ? 1 : 5,
        mt: 5,
        mb: queue.length === 0 ? 5 : 1,
        border: "1px solid",
        borderColor: "border.secondary",
        borderRadius: 5
      }}
    >
      <Grid
        container
        gap={2}
        sx={{
          mx: isXs ? 3 : 5,
          my: 3
        }}
      >
        <Grid container gap={1} minHeight={64}>
          {images.map((image, index) => (
            <Grid item key={image} sx={{ position: "relative" }}>
              <img
                src={image}
                alt=""
                height={60}
                style={{
                  borderRadius: 5
                }}
              />
              <CloseRoundedIcon
                sx={{
                  fontSize: 10,
                  position: "absolute",
                  top: 2,
                  right: 2,
                  cursor: "pointer",
                  backgroundColor: "border.primary",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "border.secondary"
                  }
                }}
                onClick={() => {
                  track("Remove image icon clicked on dashboard");
                  removeImage(index);
                }}
              />
            </Grid>
          ))}
          {images.length < IMAGE_UPLOAD_LIMIT && (
            <IconButton
              component="label"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent"
                }
              }}
              onClick={() => {
                track("Add image button clicked on dashboard");
                removeTooltip();
              }}
            >
              <Tooltip open={showTooltip === "true"} title="Add more images" arrow placement="right">
                <AddRoundedIcon
                  fontSize="large"
                  sx={{
                    color: "border.secondary",
                    cursor: "pointer",
                    mb: 0.6
                  }}
                />
              </Tooltip>

              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                hidden
                multiple
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let files = event.target.files;
                  if (!files) return;
                  track("Images selected on dashboard");
                  updateImages(files);
                }}
              />
            </IconButton>
          )}
        </Grid>

        {jobs.map((job, index) => (
          <Grid
            item
            sx={{
              width: isXs || isSm ? "100%" : "48%"
            }}
            key={index}
          >
            <Job order={job.order} />
          </Grid>
        ))}
        {jobs.length < activeJobTypes.length && (
          <Grid item sx={{ mt: 0.3, ml: -1 }}>
            <IconButton
              component="label"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent"
                }
              }}
              onClick={() => {
                updateJobs({
                  order: jobs.length,
                  type: getJobTypeThatIsNotPresentYet(),
                  options: {}
                });
                removeChainProcessingTooltip();
              }}
            >
              <Tooltip
                open={showChainProcessingTooltip === "true"}
                title="Add more processes for chained processing"
                arrow
                placement="right"
              >
                <AddRoundedIcon
                  fontSize="large"
                  sx={{
                    color: "border.secondary",
                    cursor: "pointer"
                  }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
        )}
        <Grid container gap={1}>
          <Button
            variant="contained"
            disabled={jobs.length === 0 || images.length === 0}
            onClick={() => {
              track("Start button clicked on dashboard");
              setQueue([...queue, { images, jobs }]);
              goToGetStartedPage();
            }}
          >
            Start
          </Button>
          <Button
            variant="text"
            disabled={images.length === 0}
            onClick={() => {
              track("Clear photos button clicked on dashboard");
              goToGetStartedPage();
            }}
            sx={{ p: 1 }}
          >
            Clear photos
          </Button>
          <Button
            variant="text"
            disabled={jobs.length === 0}
            onClick={() => {
              track("Clear jobs button clicked on dashboard");
              clearJobs();
            }}
            sx={{ pl: 1, pr: 0 }}
          >
            Clear processes
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
