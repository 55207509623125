import { Button, Grid, Typography } from "@mui/material";
import Rating from "../../assets/rating.svg";
import Review1 from "../../assets/review/review1.webp";
import Review2 from "../../assets/review/review2.webp";
import Review3 from "../../assets/review/review3.webp";
import useBreakpoints from "../../hooks/useBreakpoints";
interface User {
  readonly image: string;
  readonly name: string;
}

interface HappyClientProps {
  readonly description: string;
  readonly user: User;
}

function HappyClient(props: HappyClientProps) {
  const { isXs } = useBreakpoints();
  return (
    <Grid
      item
      sx={{
        backgroundColor: "card.primary",
        borderRadius: isXs ? 10 : 4,
        p: "30px 24px",
        mb: isXs ? 2 : 0
      }}
    >
      <img src={Rating} alt="" width={150} />
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          width: isXs ? "auto" : 305,
          mt: 1
        }}
      >
        {props.description}
      </Typography>
      <Grid
        container
        sx={{
          mt: 4,
          pt: 3,
          borderTop: "1px solid",
          borderColor: "border.secondary",
          alignItems: "center"
        }}
      >
        <img
          src={props.user.image}
          alt=""
          width={48}
          height={48}
          style={{
            borderRadius: 32
          }}
        />
        <Grid item sx={{ ml: 1.5 }}>
          <Typography variant="subtitle1">{props.user.name}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function HappyClients() {
  const { isXs } = useBreakpoints();

  return (
    <Grid container mx={isXs ? 2 : 5} my={isXs ? 6 : 12}>
      <Grid container justifyContent={isXs ? "center" : "space-between"}>
        <Grid item>
          <Typography
            variant={isXs ? "h4" : "h2"}
            sx={{ fontWeight: 400, ml: isXs ? "auto" : 1, mb: isXs ? 0 : 3 }}
          >
            Happy clients
          </Typography>
        </Grid>
        {!isXs && (
          <Grid item>
            <Button
              variant="outlined"
              sx={{ ml: 1, my: 2 }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=intechsolutions.photoenhancer",
                  "_blank"
                )
              }
            >
              View More Reviews
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container justifyContent={"space-between"} my={2}>
        <HappyClient
          description="Fantastic ability to enhance image quality. The best one out there for sure!"
          user={{
            image: Review1,
            name: "Claudia Narvaez"
          }}
        />
        <HappyClient
          description="Just enhanced my first photo and, I have to say that I am impressed 👍"
          user={{
            image: Review3,
            name: "Hasan Chowdhury"
          }}
        />
        {!isXs && (
          <HappyClient
            description="Works like magic in seconds! Simply incredible!♥️👏👏"
            user={{
              image: Review2,
              name: "Alicia Chan"
            }}
          />
        )}
      </Grid>
      {isXs && (
        <Grid container justifyContent="center">
          <Button variant="outlined">View More Reviews</Button>
        </Grid>
      )}
    </Grid>
  );
}
