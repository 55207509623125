import { Components, PaletteOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { lightPalette } from "./lightPalette";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    button: Button;
    card: Card;
    border: Border;
    dot: Dot;
    footer: Footer;
  }
  interface Button {
    primary: string;
    secondary: string;
  }
  interface Card {
    primary: string;
    secondary: string;
  }
  interface Border {
    primary: string;
    secondary: string;
  }
  interface Dot {
    primary: string;
  }
  interface Footer {
    primary: string;
    secondary: string;
  }
}

const typography: TypographyOptions = {
  button: {
    lineHeight: 1,
    letterSpacing: 0.25,
    textTransform: "none",
  },
};

const components = (palette: PaletteOptions) =>
  ({
    MuiButton: {
      defaultProps: { variant: "outlined" },
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "15px 22px",
          boxShadow: "none",
          fontSize: 14,
        },
        outlined: {
          borderColor: palette.button.primary,
          color: palette.button.primary,
        },
        contained: {
          backgroundColor: palette.button.primary,
        },
        text: { color: palette.button.secondary },
      },
    },
  } as Components);

export const lightTheme = createTheme({
  palette: lightPalette,
  typography: typography,
  components: components(lightPalette),
});
