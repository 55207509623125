import { Collapse, Grid, Typography } from "@mui/material";
import { useState } from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

interface BenefitsCardProps {
  readonly title: string;
  readonly description: string;
  readonly icon: string;
  readonly expanded?: boolean;
}

export default function BenefitsCard(props: BenefitsCardProps) {
  const { isXs } = useBreakpoints();
  const [expanded, setExpanded] = useState(props.expanded || false);

  return (
    <Grid
      item
      sx={{
        backgroundColor: "secondary.main",
        borderRadius: isXs ? 8 : 4,
        width: isXs ? "100%" : 230,
        height: isXs ? "auto" : 230,
        minHeight: 112,
        p: 3,
        margin: 1,
        position: "relative",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <img
        src={props.icon}
        alt=""
        width={isXs ? 60 : 40}
        style={{
          position: isXs ? "absolute" : "static",
        }}
      />
      <Grid
        item
        sx={{
          pl: isXs ? 10 : 0,
          height: isXs ? 60 : "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          display={isXs ? "inline" : "block"}
          sx={{ pt: isXs ? 0 : 0.5 }}
        >
          {props.title}
        </Typography>
      </Grid>

      <Collapse in={!isXs || expanded}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", pt: isXs ? 3 : 1.5 }}
        >
          {props.description}
        </Typography>
      </Collapse>
    </Grid>
  );
}
