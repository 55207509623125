import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot as QuerySnapshot
} from "firebase/firestore";

export interface Base {
  readonly id?: string;
  readonly deleted?: boolean;
}

export const toEntity = <T,>(doc: DocumentSnapshot<any>): T => ({
  ...doc.data(),
  id: doc.id
});

export const toEntities = <T,>(docs: DocumentSnapshot<any>[]): T[] =>
  docs.map((doc) => toEntity(doc));

export type FirebaseDoc = DocumentSnapshot<any>;
export type QueryDocumentSnapshot = QuerySnapshot<DocumentData>;

export interface GetManyResult<T> {
  readonly data: T[];
  readonly lastDoc?: FirebaseDoc;
}
