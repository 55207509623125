import { Grid, Typography } from "@mui/material";
import PhotoRestoreIcon from "../../assets/photo-restore-icon.svg";
import PhotoEnhancerIcon from "../../assets/photo-enhancer-icon.svg";
import AppStoreButton from "../../assets/app-store-button.svg";
import GooglePlayButton from "../../assets/google-play-button.svg";
import AppScreen from "../../assets/app-screen.webp";
import useBreakpoints from "../../hooks/useBreakpoints";

export default function MobileApp() {
  const { isXs, isMd, isSm } = useBreakpoints();

  return (
    <Grid container sx={{ mx: isXs ? 0 : 5, my: isXs ? 4 : 10 }}>
      <Grid container sx={{ mb: 1, ml: isXs ? 3 : 0 }}>
        <Grid
          container
          sx={{
            backgroundColor: "#F5F5F5",
            width: "auto",
            borderRadius: isXs ? 4 : 3,
            alignItems: "center",
            p: 1,
            mr: 2,
            mb: 1
          }}
        >
          <img src={PhotoRestoreIcon} alt="" width={52} />
          <Typography variant="subtitle1" display="inline" sx={{ pl: 1 }}>
            AI Photo Restore
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: "#F5F5F5",
            width: "auto",
            borderRadius: isXs ? 4 : 3,
            alignItems: "center",
            p: 1,
            mb: 1
          }}
        >
          <img src={PhotoEnhancerIcon} alt="" width={52} />
          <Typography variant="subtitle1" display="inline" sx={{ pl: 1 }}>
            AI Photo Enhancer
          </Typography>
        </Grid>
      </Grid>
      {isXs && (
        <img
          src={AppScreen}
          alt=""
          width={"95%"}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            right: isXs ? 10 : undefined,
            zIndex: isXs ? -1 : 0,
            marginBottom: -289
          }}
        />
      )}
      <Grid
        container
        sx={{
          backgroundColor: "card.primary",
          borderRadius: isXs ? 10 : 5,
          py: 4,
          px: isXs ? 2 : 4,
          marginY: 1.5,
          position: "relative"
        }}
      >
        <Grid item sx={{ maxWidth: isXs ? "auto" : 390 }}>
          <Typography variant="h4" sx={{ pb: 3 }}>
            Improve your smartphone photos
          </Typography>
          <Typography variant="body1" sx={{ pb: isXs ? 4 : 6, color: "text.secondary" }}>
            All the tools to improve the quality of your photos in two mobile apps. Simple, fast, impressive!
          </Typography>
          <Grid item>
            <img
              src={AppStoreButton}
              alt=""
              width={150}
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/us/app/ai-photo-enhance-unblur-clear/id1599612633",
                  "_blank"
                );
              }}
            />
            <img
              src={GooglePlayButton}
              alt=""
              width={150}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=intechsolutions.photoenhancer",
                  "_blank"
                );
              }}
            />
          </Grid>
        </Grid>
        {!isXs && (
          <img
            src={AppScreen}
            alt=""
            width={isMd ? 232 : isSm ? 155 : 250}
            style={{
              position: "absolute",
              bottom: 0,
              left: isMd ? 430 : isSm ? 420 : 600,
              borderTopLeftRadius: 14,
              borderTopRightRadius: 14
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
