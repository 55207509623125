import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Job } from "./DashboardContext";
import { saveAs } from "file-saver";
import JSZip from "jszip";

export interface QueueItem {
  readonly images: string[];
  readonly jobs: Job[];
}

export interface QueueContextValue {
  readonly queue: QueueItem[];
  readonly setQueue: (queue: QueueItem[]) => void;
  readonly intermediaryImages: string[];
  readonly setIntermediaryImages: Dispatch<SetStateAction<string[]>>;
  readonly saveIntermediaryImages: () => void;
  readonly finalImages: string[];
  readonly setFinalImages: Dispatch<SetStateAction<string[]>>;
  readonly saveFinalImages: () => void;
  readonly saveImage: (image: string) => void;
}

interface ProviderProps {
  children: ReactNode;
}

export const QueueContext = createContext<QueueContextValue>({} as any);

export const QueueContextProvider = (props: ProviderProps) => {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [intermediaryImages, setIntermediaryImages] = useState<string[]>([]);
  const [finalImages, setFinalImages] = useState<string[]>([]);

  const saveIntermediaryImages = () => {
    saveImagesInZip(intermediaryImages);
  };

  const saveFinalImages = () => {
    saveImagesInZip(finalImages);
  };

  const saveImagesInZip = async (imageUrls: string[]) => {
    const zip = new JSZip();
    const imageFiles = await downloadFiles(imageUrls);
    imageFiles.forEach((imageFile) => zip.file(imageFile.name, imageFile.file));
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "enhanced.zip");
  };

  const downloadFiles = async (images: string[]) => {
    return await Promise.all(
      images.map(async (image) => ({
        file: await (await fetch(image)).blob(),
        name: image.split("/").pop()!
      }))
    );
  };

  const saveImage = (image: string) => {
    saveAs(image, image.split("/").pop());
  };

  return (
    <QueueContext.Provider
      value={{
        queue,
        setQueue,
        intermediaryImages,
        setIntermediaryImages,
        saveIntermediaryImages,
        finalImages,
        setFinalImages,
        saveFinalImages,
        saveImage
      }}
    >
      {props.children}
    </QueueContext.Provider>
  );
};
