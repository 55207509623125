import { Button, Grid, Typography } from "@mui/material";
import useBreakpoints from "../hooks/useBreakpoints";
import { useContext } from "react";
import { DashboardContext } from "../components/dashboard/DashboardContext";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { track } from "@amplitude/analytics-browser";

export default function DragAndDropView() {
  const { isXs } = useBreakpoints();
  const navigate = useNavigate();
  const { updateImages, updateImagesWithDrop, setGetStartedPage } = useContext(DashboardContext);

  return (
    <Dropzone
      onDrop={(files) => {
        updateImagesWithDrop(files);
        setGetStartedPage(false);
        navigate("/login");
      }}
      accept={{
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": []
      }}
      noClick
    >
      {({ getRootProps, getInputProps }) => (
        <Grid
          item
          sx={{
            backgroundColor: "secondary.main",
            border: isXs ? "2px dashed" : undefined,
            borderColor: "border.secondary",
            borderRadius: isXs ? 8 : 4,
            width: "100%"
          }}
          {...getRootProps()}
        >
          <Grid item sx={{ p: isXs ? "32px 21px" : 5, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Drag &amp; Drop your image here
            </Typography>
            <Button
              variant="contained"
              component="label"
              sx={{ mb: 2 }}
              onClick={() => track("Select image button clicked on drag and drop view")}
            >
              Select image
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                hidden
                multiple
                onChange={(event) => {
                  let files = event.target.files;
                  if (!files) return;
                  track("Images selected on drag and drop view");
                  updateImages(files);
                  setGetStartedPage(false);
                  navigate("/login");
                }}
                {...getInputProps()}
              />
            </Button>
            <Typography variant="body2" sx={{ color: "text.secondary", fontSize: isXs ? 14 : 10 }}>
              JPG or PNG. Max Size 2000x2000
            </Typography>
          </Grid>
        </Grid>
      )}
    </Dropzone>
  );
}
