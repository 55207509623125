import { createContext, ReactNode, useState } from "react";

export enum SocialMediaItemName {
  SOCIAL_SHARING = "Social sharing",
  PHOTOGRAPHERS = "Photographers",
  PRINTING_SERVICES = "Printing services",
  E_COMMERCE = "E-commerce",
  WEBSITE_GRAPHICS = "Website graphics",
}

export interface SocialMediaContextValue {
  readonly activeItem: SocialMediaItemName;
  readonly setActiveItem: (name: SocialMediaItemName) => void;
}
interface ProviderProps {
  children: ReactNode;
}

export const SocialMediaContext = createContext<SocialMediaContextValue>(
  {} as any
);

export const SocialMediaContextProvider = (props: ProviderProps) => {
  const [activeItem, setActiveItem] = useState(
    SocialMediaItemName.SOCIAL_SHARING
  );

  return (
    <SocialMediaContext.Provider value={{ activeItem, setActiveItem }}>
      {props.children}
    </SocialMediaContext.Provider>
  );
};
