import { Firestore } from "firebase/firestore";
import * as firestore from "firebase/firestore";
import { db } from "../utils/firebaseUtil";
import { GetManyResult, toEntities } from "./Common";
import { CrudRepository } from "./CrudRepository";
import { last } from "lodash";
import { Price } from "../model/price";
import { Collection } from "../model/collections";

class PriceRepository extends CrudRepository<Price> {
  constructor(db: Firestore) {
    super(db, Collection.PRICES);
  }

  public getAllByIds = async (
    productIds: string[]
  ): Promise<GetManyResult<Price>> => {
    const query = firestore.query(
      firestore.collectionGroup(this.db, this.collection),
      firestore.where("product", "in", productIds)
    );

    const snapshot = await firestore.getDocs(query);
    return {
      data: toEntities<Price>(snapshot.docs),
      lastDoc: last(snapshot.docs)
    };
  };
}

export default new PriceRepository(db);
