import { track } from "@amplitude/analytics-browser";
import { Dialog, DialogContent, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import InlineError from "../../common/InlineError";
import LoadingButton from "../../common/LoadingButton";
import { GlobalContext } from "../../context/GlobalContext";
import { validateEmail } from "../../utils/validators";

interface PasswordResetDialogProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export default function PasswordResetDialog(props: PasswordResetDialogProps) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string>();
  const { sendForgotPasswordEmail } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const emailIsValid = (): boolean => {
    const emailError = validateEmail(email);
    setEmailError(emailError);
    return !emailError;
  };

  const handleSubmit = async () => {
    track("Email me button clicked on password reset dialog");
    try {
      if (emailIsValid()) {
        setLoading(true);
        await sendForgotPasswordEmail(email);
        toast.success("Password reset email sent successfully");
        setEmail("");
        setEmailError("");
        props.onClose();
      }
    } catch (e: any) {
      toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="xs"
      onClose={() => {
        props.onClose();
        setEmail("");
        setEmailError("");
      }}
    >
      <DialogContent>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Forgot Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              We will send you an email with instructions on how to reset your password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              name="email"
              required
              fullWidth
              error={!!emailError}
              id="email"
              label="Email"
              autoFocus
            />
            {emailError && <InlineError msg={emailError} />}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton onClick={handleSubmit} loading={loading} variant="contained" disabled={!email}>
              Email Me
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
