import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PaymentIcon from "@mui/icons-material/Payment";
import HomeIcon from "@mui/icons-material/Home";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import React, { useContext, useState } from "react";
import { auth } from "../utils/firebaseUtil";
import { Chip, Grid } from "@mui/material";
import { GlobalContext } from "../context/GlobalContext";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import useBreakpoints from "../hooks/useBreakpoints";
import { DashboardContext } from "./dashboard/DashboardContext";
import { track } from "@amplitude/analytics-browser";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    track("Account menu avatar clicked on navbar");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user } = useContext(GlobalContext);
  const { clearImages, clearJobs } = useContext(DashboardContext);
  const navigate = useNavigate();
  const { isXs } = useBreakpoints();

  return (
    <>
      {user && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          {auth.currentUser && (
            <Grid container gap={1}>
              <Chip
                style={{ textDecoration: "none", cursor: "pointer" }}
                label={`Tokens: ${user.token || 0}`}
                onClick={() => {
                  track("Tokens chip clicked on navbar");
                  navigate("/pricing");
                }}
              />
              {!isXs && (
                <Chip
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  label="Pricing"
                  onClick={() => {
                    track("Pricing chip clicked on navbar");
                    navigate("/pricing");
                  }}
                />
              )}
            </Grid>
          )}
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: isXs ? 0 : 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{user?.firstName?.charAt(0)}</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{ width: 180 }}
          onClick={() => {
            track("Dashboard clicked in account menu dropdown");
            navigate("/");
          }}
        >
          <Avatar>
            <HomeIcon fontSize="small" />
          </Avatar>{" "}
          Dashboard
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            track("Pricing clicked in account menu dropdown");
            navigate("/pricing");
          }}
        >
          <ListItemIcon>
            <PaymentIcon fontSize="small" />
          </ListItemIcon>
          Pricing
        </MenuItem>
        <MenuItem
          onClick={() => {
            track("Settings clicked in account menu dropdown");
            navigate("/settings");
          }}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={async () => {
            track("Logout clicked in account menu dropdown");
            await signOut(auth);
            clearImages();
            clearJobs();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
