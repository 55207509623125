import { Grid } from "@mui/material";
import { useContext } from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";
import ECommerceContent from "./content/ECommerceContent";
import PhotographersContent from "./content/PhotographersContent";
import PrintingServicesContent from "./content/PrintingServicesContent";
import SocialSharingContent from "./content/SocialSharingContent";
import WebsiteGraphicsContent from "./content/WebsiteGraphicsContent";
import { SocialMediaContext, SocialMediaItemName } from "./SocialMediaContext";

export default function SocialMediaContent() {
  const { isXs } = useBreakpoints();
  const { activeItem } = useContext(SocialMediaContext);

  const getSocialMediaContent = () => {
    switch (activeItem) {
      case SocialMediaItemName.SOCIAL_SHARING:
        return <SocialSharingContent />;
      case SocialMediaItemName.PHOTOGRAPHERS:
        return <PhotographersContent />;
      case SocialMediaItemName.PRINTING_SERVICES:
        return <PrintingServicesContent />;
      case SocialMediaItemName.E_COMMERCE:
        return <ECommerceContent />;
      case SocialMediaItemName.WEBSITE_GRAPHICS:
        return <WebsiteGraphicsContent />;
    }
  };

  return <Grid sx={{ m: isXs ? 2 : 0 }}>{getSocialMediaContent()}</Grid>;
}
