import { Grid } from "@mui/material";
import { useContext } from "react";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { QueueContext } from "../QueueContext";
import ProgressBar from "./ProgressBar";

export default function ProgressView() {
  const { queue } = useContext(QueueContext);
  const { isXs } = useBreakpoints();

  return (
    <Grid container sx={{ mb: 1, pr: isXs ? 2 : 10 }} flexDirection="column-reverse">
      {queue.map((queueItem, i) =>
        queueItem.images.map((image, j) => {
          return <ProgressBar image={image} key={"" + i + j} queueItem={queueItem} />;
        })
      )}
    </Grid>
  );
}
