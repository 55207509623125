import { Grid } from "@mui/material";
import Photo1Mobile from "../../../../assets/socialmedia/photographers1-mobile.webp";
import Photo2Mobile from "../../../../assets/socialmedia/photographers2-mobile.webp";
import Photo1Web from "../../../../assets/socialmedia/photographers1-web.webp";
import Photo2Web from "../../../../assets/socialmedia/photographers2-web.webp";
import Photo3Web from "../../../../assets/socialmedia/photographers3-web.webp";
import useBreakpoints from "../../../../hooks/useBreakpoints";

export default function PhotographersContent() {
  const { isXs } = useBreakpoints();

  return (
    <Grid
      container={isXs}
      justifyContent={"center"}
      sx={{ position: "relative" }}
    >
      <img
        src={isXs ? Photo1Mobile : Photo1Web}
        alt=""
        width={isXs ? "100%" : 330}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          left: 30,
          top: 20,
          borderRadius: 24,
        }}
      />
      <img
        src={isXs ? Photo2Mobile : Photo2Web}
        alt=""
        width={isXs ? "100%" : 245}
        style={{
          objectFit: "cover",
          position: isXs ? "static" : "absolute",
          left: 400,
          top: 20,
          marginTop: isXs ? 15 : 0,
          borderRadius: isXs ? 24 : 1000,
        }}
      />
      {!isXs && (
        <img
          src={Photo3Web}
          alt=""
          width={isXs ? "100%" : 245}
          style={{
            objectFit: "cover",
            position: isXs ? "static" : "absolute",
            left: 400,
            top: 292,
            borderRadius: 24,
          }}
        />
      )}
    </Grid>
  );
}
