import { Grid, Typography } from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { SocialMediaItemName } from "./SocialMediaContext";

interface SocialMediaItemProps {
  readonly name: SocialMediaItemName;
  readonly description: string;
  readonly isActive: boolean;
  readonly setActive: (name: SocialMediaItemName) => void;
  readonly mr?: number;
}

export default function SocialMediaItem(props: SocialMediaItemProps) {
  const { isXs } = useBreakpoints();
  return (
    <Grid
      item
      sx={{
        ml: isXs ? 2 : 0,
        mr: (isXs && props.mr) || 0,
        my: 2,
        p: 2.5,
        width: 403,
        minWidth: 212,
        backgroundColor: props.isActive ? "primary.main" : "card.primary",
        borderRadius: isXs ? 6 : 4,
        cursor: "pointer",
      }}
      onClick={() => props.setActive(props.name)}
    >
      <Typography
        variant="h6"
        color={props.isActive ? "secondary.main" : "button.secondary"}
        pb={1}
      >
        {props.name}
      </Typography>
      <Typography
        variant="body2"
        color={props.isActive ? "#FFFFFF99" : "text.secondary"}
      >
        {props.description}
      </Typography>
    </Grid>
  );
}
