import { Chip, Dialog, DialogContent, Grid } from "@mui/material";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import BeforeAfterIcon from "../../../assets/before-after-icon.svg";

interface TimelineDialogProps {
  readonly jobName: string;
  readonly image: string;
  readonly secondImage?: string;
  readonly secondJobName?: string;
  readonly isOpen: boolean;
  readonly onClose: (event: any, reason: any) => void;
}

export default function TimelineDialog(props: TimelineDialogProps) {
  return (
    <Dialog open={props.isOpen} maxWidth="md" onClose={props.onClose}>
      <DialogContent>
        <Grid container textAlign="center">
          {props.secondImage && props.secondJobName ? (
            <Grid item>
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  overflow: "hidden"
                }}
              >
                <ReactBeforeSliderComponent
                  firstImage={{
                    imageUrl: props.secondImage
                  }}
                  secondImage={{
                    imageUrl: props.image
                  }}
                  delimiterIconStyles={{
                    width: 40,
                    height: 40,
                    backgroundImage: `url(${BeforeAfterIcon})`
                  }}
                />
              </Grid>
              <Grid container justifyContent="space-around">
                <Chip
                  label={props.secondJobName}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "secondary.main",
                    mt: 1.5
                  }}
                />
                <Chip
                  label={props.jobName}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "secondary.main",
                    mt: 1.5
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <img
                src={props.image}
                alt=""
                style={{
                  borderRadius: 5,
                  border: "1px solid transparent",
                  cursor: "pointer",
                  display: "block",
                  margin: "auto",
                  maxHeight: "90vh",
                  maxWidth: "100%"
                }}
              />
              <Chip
                label={props.jobName}
                sx={{
                  backgroundColor: "primary.main",
                  color: "secondary.main",
                  mt: 1,
                  mb: -1.2
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
