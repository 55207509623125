import { Firestore } from "firebase/firestore";
import * as firestore from "firebase/firestore";
import { db } from "../utils/firebaseUtil";
import { GetManyResult, toEntities } from "./Common";
import { CrudRepository } from "./CrudRepository";
import { last } from "lodash";
import { Collection } from "../model/collections";
import { Product } from "../model/product";

class ProductRepository extends CrudRepository<Product> {
  constructor(db: Firestore) {
    super(db, Collection.PRODUCTS);
  }

  public getAllActive = async (): Promise<GetManyResult<Product>> => {
    const query = firestore.query(
      firestore.collection(this.db, this.collection),
      firestore.where("active", "==", true)
    );

    const snapshot = await firestore.getDocs(query);

    return {
      data: toEntities<Product>(snapshot.docs),
      lastDoc: last(snapshot.docs)
    };
  };
}

export default new ProductRepository(db);
