import { Button, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";
import { QueueContext } from "./QueueContext";

export default function BuyTokenView() {
  const navigate = useNavigate();
  const { queue } = useContext(QueueContext);
  const { isXs } = useBreakpoints();

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{
        mt: 5,
        mx: isXs ? 1 : 5,
        mb: queue.length > 0 ? 1 : 5,
        border: "1px solid",
        borderColor: "border.secondary",
        borderRadius: 5,
        height: queue.length > 0 ? 278 : "60vh"
      }}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="subtitle2" sx={{ my: 1 }} textAlign={"center"}>
            You're out of tokens. Buy more for image processing.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ my: 1 }}>
        <Grid item>
          <Button variant="contained" component="label" onClick={() => navigate("/pricing")}>
            Get tokens
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
