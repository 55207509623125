import * as firestore from "firebase/firestore";
import { Collection } from "../model/collections";
import { toEntity } from "./Common";

export abstract class CrudRepository<T extends { id?: string }> {
  protected constructor(
    readonly db: firestore.Firestore,
    readonly collection: Collection,
    readonly rootCollection?: Collection
  ) {}

  public create = async (
    data: T,
    rootId?: string,
    batch?: firestore.WriteBatch
  ): Promise<T> => {
    const path = `${this.getRoot(rootId)}${this.collection}`;
    const doc = firestore.doc(
      this.db,
      path,
      data.id || this.getRandomId(rootId)
    );
    batch ? batch.set(doc, data) : await firestore.setDoc(doc, data);
    return { ...data, id: doc.id };
  };

  public createMany = async (data: T[], rootId?: string): Promise<T[]> => {
    const batch = firestore.writeBatch(this.db);
    const saved: T[] = [];
    for (const d of data) {
      saved.push(await this.create(d, rootId, batch));
    }
    await batch.commit();
    return saved;
  };

  public update = async (
    data: T,
    rootId?: string,
    batch?: firestore.WriteBatch
  ): Promise<T> => {
    const doc = firestore.doc(
      this.db,
      `${this.getRoot(rootId)}${this.collection}/${data.id}`
    );
    batch ? batch.update(doc, data) : await firestore.updateDoc(doc, data);
    return { ...data, ...data };
  };

  public updateMany = async (data: T[], rootId?: string): Promise<T[]> => {
    const batch = firestore.writeBatch(this.db);
    const updated: T[] = [];
    for (const d of data) {
      updated.push(await this.update(d, rootId, batch));
    }
    await batch.commit();
    return updated;
  };

  public deleteById = async (
    id: string,
    rootId?: string,
    batch?: firestore.WriteBatch
  ): Promise<void> => {
    const doc = firestore.doc(
      this.db,
      `${this.getRoot(rootId)}${this.collection}/${id}`
    );
    if (batch) {
      batch.delete(doc);
    } else {
      await firestore.deleteDoc(doc);
    }
  };

  public getById = async (
    id: string,
    rootId?: string
  ): Promise<T | undefined> => {
    const docRef = firestore.doc(
      this.db,
      `${this.getRoot(rootId)}${this.collection}/${id}`
    );
    const doc = await firestore.getDoc(docRef);
    return doc.exists() ? toEntity(doc) : undefined;
  };

  public getManyByIds = async (
    ids: string[],
    rootId?: string
  ): Promise<T[]> => {
    if (ids.length === 0) return [];
    const entities = await Promise.all(
      ids.map((id) => this.getById(id, rootId))
    );
    return entities.filter((entity) => entity !== undefined) as T[];
  };

  public getByIdLive = (
    id: string,
    onSuccess: (data: T | undefined) => void,
    onError?: (error: firestore.FirestoreError) => void,
    rootId?: string
  ) =>
    firestore.onSnapshot(
      firestore.doc(this.db, `${this.getRoot(rootId)}${this.collection}/${id}`),
      (snapshot) =>
        onSuccess(snapshot.exists() ? toEntity(snapshot) : undefined),
      (error) => onError && onError(error)
    );

  private getRoot = (rootId?: string): string =>
    this.rootCollection && rootId ? `${this.rootCollection}/${rootId}/` : "";

  public getRandomId = (rootId?: string) =>
    firestore.doc(
      firestore.collection(this.db, `${this.getRoot(rootId)}${this.collection}`)
    ).id;
}
